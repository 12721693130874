import { formatDate } from '../../../../../src/common/utils/formatters';

(function () {
  "use strict";
  angular
    .module("dstreamApp.views.detailed-transactions")
    .component("dsDetailedTransactionsTable", {
      templateUrl: "app/views/detailed-transactions/components/detailed-transactions-table/detailed-transactions-table.html",
      controller: DetailedTransactionsTable,
      bindings: {
        tableHeight: "<",
        invoice: "<"
      }
    }).config(ServiceDecorator);
  DetailedTransactionsTable.$inject = [
    "$rootScope",
    "$scope",
    "$window",
    "uiGridEditConstants",
    "uiGridCellNavConstants",
    "$filter",
    "detailedTransactionsTableService",
    "detailedTransactionsTableTemplates",
    "invoiceService",
    "activeInvoiceService",
    "authenticationService",
    "autoTransactionsService",
    "$uibModal",
    "notifyService",
    "webServices",
    "companyDataService",
    "confirmationService"
  ];

  function DetailedTransactionsTable (
    $rootScope,
    $scope,
    $window,
    uiGridEditConstants,
    uiGridCellNavConstants,
    $filter,
    detailedTransactionsTableService,
    detailedTransactionsTableTemplates,
    invoiceService,
    activeInvoiceService,
    authenticationService,
    autoTransactionsService,
    $uibModal,
    notifyService,
    webServices,
    companyDataService,
    confirmationService
  ) {
    var ctrl = this;
    ctrl.addNewColumTemplate = "";
    ctrl.byPassScrollbarCalculation = false;
    ctrl.canvas = null;
    ctrl.gridApi = null;
    ctrl.isMergeDisabled = true;
    ctrl.rowsToBeMerged = [];
    ctrl.VatRates = {};
    ctrl.showTable = false;
    ctrl.tableReloading = false;
    ctrl.tableDataLoading = true;
    ctrl.tableHasHorizontalScrollbar = false;
    ctrl.tableHasVerticalScrollbar = false;
    ctrl.viewport = null;
    ctrl.watchers = [];
    ctrl.dateOptions = { // date options for the calendar
      showWeeks: false,
      startingDay: 1,
      columnValue: null,
      shortcutPropagation: true
    };
    ctrl.addNewColumnPopover = {
      open: false,
      template: "ui-grid/addNewColumn",
      cancel: cancelNewColumnAdd,
      add: addNewColumn,
      newColumnValue: null,
      selectedNewColumn: null,
      select: selectNewColumn,
      invalid: false
    };
    ctrl.deleteRowPopover = {
      template: "detailed-transactions-row-delete-template.html",
      show: function (row) {
        row.showDeletePopover = true;
      },
      cancel: function (row) {
        row.showDeletePopover = false;
      },
      delete: function (row) {
        // confirm and rewrite all transaction rows
        deleteRow(row);
        row.showDeletePopover = false;
      }
    };
    ctrl.tableOptions = {
      enableSorting: false,
      enableColumnMenus: false,
      minRowsToShow: 5,
      rowHeight: 50,
      enableColumnResizing: true,
      keyDownOverrides: [{keyCode: 46}],
      enableCellEditOnFocus: false,
      rowEditWaitInterval: 100,
      columnVirtualizationThreshold: 100,
      appScopeProvider: ctrl,
      columnDefs: [
        {
          field: "editRow",
          name: "",
          priority: 1,
          width: 30,
          pinnedLeft: true,
          enableColumnResizing: false,
          enableCellEdit: false,
          allowCellFocus: false,
          cellClass: "no-edit ui-grid__row-edit",
          cellTemplate: "ui-grid/uiGridCell/rowEdit"
        },
        {
          field: "Description",
          name: "description",
          displayName: $filter("translate")("component.additionalInfo.description"),
          priority: 2,
          minWidth: 100,
          width: 250,
          pinnedLeft: true,
          cellTemplate: "ui-grid/uiGridCell/description",
          editableCellTemplate: "ui-grid/cellEditor/text"
        },
        {
          field: "SumWithoutVat",
          name: "SumWithoutVat",
          displayName: $filter("translate")("component.transactionRows.sum"),
          priority: 3,
          minWidth: 65,
          width: 100,
          pinnedLeft: true,
          dependantOnVAT: true,
          cellEditableCondition: function ($scope) {
            return $scope.row.entity.VatCode;
          },
          cellClass: function (grid, row) {
            return !row.entity.VatCode ? "no-edit disabled" : "";
          },
          headerCellClass: "no-menu",
          cellTemplate: "ui-grid/uiGridCell/number",
          editableCellTemplate: "ui-grid/cellEditor/money"
        },
        {
          field: "VAT",
          name: "VAT",
          displayName: $filter("translate")("component.transactionRows.VatSum"),
          priority: 4,
          minWidth: 60,
          width: 100,
          pinnedLeft: true,
          enableCellEdit: false,
          allowCellFocus: false,
          cellClass: "no-edit disabled",
          headerCellClass: "no-menu",
          cellTemplate: "ui-grid/uiGridCell/number"
        },
        {
          field: "Total",
          name: "Total",
          displayName: $filter("translate")("component.transactionRows.sumWithVAT"),
          priority: 5,
          minWidth: 115,
          width: 115,
          pinnedLeft: true,
          dependantOnVAT: true,
          cellEditableCondition: function ($scope) {
            return $scope.row.entity.VatCode;
          },
          allowCellFocus: function (grid, row) {
            return row.entity.VatCode;
          },
          cellClass: function (grid, row) {
            return !row.entity.VatCode ? "no-edit disabled" : "";
          },
          headerCellClass: "no-menu",
          cellTemplate: "ui-grid/uiGridCell/number",
          editableCellTemplate: "ui-grid/cellEditor/money"
        },
        {
          field: "VatCode",
          name: "VATcode",
          displayName: $filter("translate")("component.transactionRows.VAT"),
          priority: 7,
          minWidth: 125,
          headerCellTemplate: "ui-grid/uiGridHeaderCellContentCopyable/typeahead",
          cellTemplate: "ui-grid/uiGridCell/typeahead",
          refParameter: "Description",
          refAsParameter: "Description",
          editableCellTemplate: "ui-grid/cellEditor/typeahead",
          typeaheadOptions: detailedTransactionsTableService.getAvailableVatCodes,
          filterFunction: detailedTransactionsTableService.filterVatCodes,
          cellEditableCondition: function ($scope) {
              // disable editing if we do not have any VatCodes to select
              const colDef = $scope.col.colDef;
              return colDef.typeaheadOptions().length > 0;
          },
        },
        {
          field: "Account",
          name: "account",
          displayName: $filter("translate")("component.transactionRows.account"),
          priority: 6,
          minWidth: 125,
          headerCellTemplate: "ui-grid/uiGridHeaderCellContentCopyable/typeahead",
          cellTemplate: "ui-grid/uiGridCell/typeahead",
          refParameter: "Description",
          refAsParameter: "Description",
          editableCellTemplate: "ui-grid/cellEditor/typeahead",
          typeaheadOptions: detailedTransactionsTableService.getAvailableAccounts,
          filterFunction: detailedTransactionsTableService.filterAccounts,
          cellEditableCondition: function ($scope) {
              // disable editing if we do not have any Accounts to select
              const colDef = $scope.col.colDef;
              return colDef.typeaheadOptions(null, true);
          },
        }
      ],
      onRegisterApi: function (gridApi) {
        ctrl.gridApi = gridApi;
        // save cell value to DB
        gridApi.edit.on.afterCellEdit($scope, onCellEdited);
        // override button presses in the grid to handle custom logic
        gridApi.cellNav.on.viewPortKeyDown($scope, handleGridKeyEvents);
        // bypass evt.stopPropagation() on cell focus navigation, because we want to fire our custom
        // directives when edited cell loses focus. Used when cell is in edit mode and we click anywhere else in the grid(focus event)
        gridApi.cellNav.on.navigate($scope, function (curRowCol, isCtrl, evt) {
          if (evt && evt.type === "focus") {
            var e = document.createEvent("HTMLEvents");
            e.initEvent("click", false, true);
            window.dispatchEvent(e);
          }
        });
        gridApi.selection.on.rowSelectionChanged($scope, checkRowsToBeMerged);
        gridApi.selection.on.rowSelectionChangedBatch($scope, checkRowsToBeMerged);
        // make sure the ellipsis are calculated when we resized the cells
        gridApi.colResizable.on.columnSizeChanged($scope, calculateEllipsis);
        // makes sure that scrollbars are calculated when we add/remove a column, autorizer fires or column is resized
        gridApi.core.registerColumnsProcessor(calculateScrollbarVisibility, 70);
        // hack to add a class to table when right part of the table has a horizontal scrollbar
        // used to ajust the pinned viewport height to fix the rows alignment issue. might get fixed in original library at some point
        setTimeout(function () {
          //gridUtil.on.mousewheel(document.querySelectorAll('.ui-grid-render-container-body')[0], function() {});
          ctrl.canvas = document.querySelectorAll(".ui-grid-render-container-body .ui-grid-canvas")[0];
          ctrl.viewport = document.querySelectorAll(".ui-grid-render-container-body .ui-grid-viewport")[0];
          // if cells have rendered correctly then make sure the first colum(checkboxes) isn't focusable
          if (ctrl.gridApi.grid.columns.length && ctrl.gridApi.grid.columns[0].colDef.name === "selectionRowHeaderCol") {
            ctrl.gridApi.grid.columns[0].colDef.allowCellFocus = false;
          }
        });
      }
    }; // default options for the entire table
    ctrl.$onInit = init;
    ctrl.$onDestroy = destroy;
    ctrl.addNewRow = addNewRow;
    ctrl.buildPossibleNewColumnsArray = detailedTransactionsTableService.buildPossibleNewColumnsArray;
    ctrl.calculateScrollbarVisibility = calculateScrollbarVisibility;
    ctrl.copyColumnToAllRows = copyColumnToAllRows;
    ctrl.copyContentToRowsBelow = copyContentToRowsBelow;
    ctrl.getDimensionNameById = detailedTransactionsTableService.getDimensionNameById;
    ctrl.isCellMandatory = isCellMandatory;
    ctrl.isColumnRemovable = isColumnRemovable;
    ctrl.onCelleditCancel = onCelleditCancel;
    ctrl.onCelleditSelect = onCelleditSelect;
    ctrl.deleteRow = deleteRow;
    ctrl.deleteRows = deleteRows;
    ctrl.openSplitModal = openSplitModal;
    ctrl.removeColumn = removeColumn;
    ctrl.setTableLoading = setTableLoading;
    ctrl.updateDimension = updateDimension;
    ctrl.moveFocus = moveFocus;
    ctrl.showCombinePopover = showCombinePopover;
    ctrl.showApplyTemplate = showApplyTemplate;
    ctrl.openApplyAutoTransactionTemplate = openApplyAutoTransactionTemplate;
    ctrl.openSaveAutoTransactionSnapshot = openSaveAutoTransactionSnapshot;
    ctrl.openCombineModal = openCombineModal;
    ctrl.hasDimensions = hasDimensions;
    ctrl.isFinalConfirmation = isFinalConfirmation;

    function init () {
      detailedTransactionsTableTemplates.$init();
      initScrollbarVisibilityWatchers();
      // watch for changes in datepicker in columnHeader
      // we use a watcher because the bootstrap datepicker library doesnt give any events on date selection
      ctrl.watchers.push($scope.$watch(function () {
        return ctrl.tableOptions.data;
      }, function (newVal, oldVal) {
        if (newVal && oldVal && ctrl.invoice) {
          if (oldVal.length === newVal.length) {
            for (var i = 0; i < oldVal.length; i++) {
              if (newVal[i].VatRate !== oldVal[i].VatRate) {
                canMergeRows();
              }
            }
          } else {
            canMergeRows();
          }
        }
      }));
      ctrl.watchers.push($scope.$watch(function () {
        return ctrl.invoice;
      }, function (newVal) {
        if (newVal) {
            const accountingDate = ctrl.invoice.AccountingDate ? ctrl.invoice.AccountingDate : undefined;
            detailedTransactionsTableService.init(accountingDate).then(() => {
                getTransactionRowsData();
            });
          canMergeRows();
        }
      }));
      ctrl.watchers.push($scope.$watch(function () {
        return ctrl.dateOptions.columnValue;
      }, function (newValue) {
        copyColumnToAllRows(newValue, _.find(ctrl.tableOptions.columnDefs, function (col) {
          return col.field === "AccountingDate";
        }));
        ctrl.dateOptions.columnValue = null;
      }));
    }

    function isFinalConfirmation() {
        return confirmationService.isFinalConfirmation(ctrl.invoice);
    }

    /*
        Do cleanup
     */
    function destroy () {
      angular.element($window).off("resize", calculateScrollbarVisibility);
      _.forEach(ctrl.watchers, function (watcher) {
        watcher();
      }); // cleanup all watchers
    }

    /* UTILITIES */
    /**
     * callback method for getting array of rows selected
     */
    function checkRowsToBeMerged () {
      ctrl.rowsToBeMerged = ctrl.gridApi.selection.getSelectedRows();
      canMergeRows();
    }

    /**
     * function for checking if to show the combine popover
     * @returns boolean TRUE if user has rights to edit invoice and the requirements for merging rows are met, else returns FALSE
     */
    function showCombinePopover () {
      if (!ctrl.invoice) return false;
      return ((ctrl.invoice.hasOwnProperty("CanEditInvoice") && ctrl.invoice.CanEditInvoice) && canUpdateAccountDistributionItem());
    }

    function canUpdateAccountDistributionItem () {
      return authenticationService.isAuthorized("CanUpdateAccountDistributionItem");
    }

    function showApplyTemplate () {
      if (!ctrl.invoice) return false;
      return !!ctrl.invoice && ctrl.invoice.hasOwnProperty("Status") && ctrl.invoice.Status <= 3;
    }

    ctrl.combineTemplatePopover = {
      template: "transactionRows-combine-popover-template.html",
      Open: false
    };

    function openCombineModal () {
      ctrl.MergeOpen = false;
      var modalInstance = $uibModal.open({
        templateUrl: "app/components/transactionRowsCombine/ds-transaction-rows-combine.tpl.html",
        controller: "DsTransactionRowsCombineController as ctrl",
        windowClass: "transaction-rows-combine-modal draggable",
        backdrop: "static",
        resolve: {
          param: function () {
            return {
              rows: ctrl.rowsToBeMerged,
              invoiceId: ctrl.invoice.Id
            };
          }
        }
      });
      modalInstance.rendered.then(function () {
        var modal = angular.element(".modal__body");
        if (modal[0].scrollHeight > modal.height()) {
          modal.addClass("custom-scrollbar");
        }
      });
      modalInstance.result.then(function (result) {
        if (result) {
          invoiceService.combineAccountDistributionItems(result.invoiceId, result.apiObject).then(
            function (response) {
              if (response) {
                getTransactionRowsData();
              }
            }
          );
        }
      });
    }

      /**
       * Check if user has possibility to select any other values for dimension
       * @param cellItem
       */
    function hasDimensions(cellItem) {
        return cellItem &&
            cellItem.CustomCostObjective &&
            cellItem.CustomCostObjective.DimensionsCount > 0;
    }

    function openApplyAutoTransactionTemplate () {
      $uibModal.open({
        templateUrl: "app/components/autoTransactions/ds-auto-transaction-apply.tpl.html",
        controller: "DsAutoTransactionApplyController",
        controllerAs: "$ctrl",
        windowClass: "auto-transaction-apply draggable",
        backdrop: "static",
        resolve: {}
      }).result.then(
        function (result) {
          if (result.template) {
            webServices.applyAutoTransactionToInvoice(result.template.Id, ctrl.invoice.Id, result.template.RecreateTransactionRows).then(
              function (response) {
                getTransactionRowsData();
                /*
                TODO @tonister: reload transaction rows here
                 */
                notifyService.success("views.invoice.partials.invoiceRows.Saving_successful", "views.invoice.partials.invoiceRows.Success", true);
              }, function (error) {
                notifyService.error("controller.invoiceConfirmationController.Error", "controller.invoiceConfirmationController.Error", true);
                console.log("ERROR:: applyAutoTransactionToInvoice BackEnd result", error);
              });
          }
        },
        function (reason) {
          console.log("AutoTransactionApply modal dismissed by user:", reason);
        }
      );
    }

    function openSaveAutoTransactionSnapshot () {
      autoTransactionsService.saveAutoTransactionSnapshot(ctrl.invoice.Id, ctrl.tableOptions.data, ctrl.TransactionRowsTotalSumRemaining);
    }

    $scope.$on("ds-detailed-transactions.reload", function (value) {
      getTransactionRowsData();
    });

    /*
        Get the transaction rows data
     */
    function getTransactionRowsData () {
      return invoiceService.getTransactionRowsData(null, 10000).then(function (data) {
        ctrl.tableOptions.data = detailedTransactionsTableService.parseAccountantRows(data, ctrl.tableOptions.columnDefs);
        ctrl.tableOptions.columnDefs = detailedTransactionsTableService.sortColumnDefs(ctrl.tableOptions.columnDefs); // sort columns
        setTimeout(function () {
          $rootScope.$emit("calculateEmptyMandatoryFields"); // check if emptyMandatoryFieldCount changed
          $scope.$broadcast("ds-detailed-transactions.reloaded");
          calculateScrollbarVisibility();
          ctrl.showTable = true;
          ctrl.tableDataLoading = false;
          ctrl.rowsToBeMerged = [];
          canMergeRows();
        });
      });
    }

    function canMergeRows() {
        if (!ctrl.invoice) {
            return;
        }
        webServices.canMergeTransactionRows(ctrl.invoice.Id, ctrl.rowsToBeMerged.map(r => r.Id)).then(
            (response) => {
                ctrl.isMergeDisabled = !response.data;
            }
        )
    }

    /*
        Set loading state for the table
     */
    function setTableLoading (loading) {
      ctrl.tableDataLoading = loading;
    }

    /*
        Emit END_CELL_EDIT event, so the ui-grid knows that we are done with the edit, so it can hide the edit template etc
     */
    function onCelleditSelect (scope) {
      setTimeout(function () {
        scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
      });
    }

    /*
        Emit CANCEL_CELL_EDIT event, so the ui-grid knows that we are cancelling the edit, so it can reset its value and hide the edit template etc
     */
    function onCelleditCancel (scope) {
      setTimeout(function () {
        scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
      });
    }

    /*
        Handle custom/override key presses in the grid
     */
    function handleGridKeyEvents (event, curRowCol) {
      var row = curRowCol.row,
        col = curRowCol.col;
      // delete cell contents on DEL key
      if (event.keyCode === 46) {
        // only allow delete in Description, not mandatory CustomFields, Comments and AccountingDate
        if ([
          "Account",
          "SumWithoutVat",
          "Total",
          "VatCode"
        ].indexOf(col.field) === -1 && !isCellMandatory(col, row)) {
          var cellValue = ctrl.gridApi.grid.getCellValue(row, col);
          if (cellValue.value) {
            cellValue.value = "";
          } else if (cellValue.DimensionId) {
            cellValue.DimensionId = null;
          }
          ctrl.gridApi.edit.raise.afterCellEdit(row.entity, col, cellValue);
        }
      }
    }

    /*
        Move cell focus in certain direction.
        Currently supports right and down(used for tab and enter key in cell edit)
     */
    function moveFocus (direction, row, col) {
      var nextRowColumn = null;
      switch (direction) {
        case "up":
          nextRowColumn = ctrl.gridApi.grid.renderContainers.body.cellNav.getRowColUp(row, col);
          break;
        case "right":
          nextRowColumn = ctrl.gridApi.grid.renderContainers.body.cellNav.getRowColRight(row, col);
          break;
        case "down":
          nextRowColumn = ctrl.gridApi.grid.renderContainers.body.cellNav.getRowColDown(row, col);
          break;
        case "left":
          nextRowColumn = ctrl.gridApi.grid.renderContainers.body.cellNav.getRowColLeft(row, col);
          break;
      }
      if (nextRowColumn) {
        ctrl.gridApi.cellNav.scrollToFocus(nextRowColumn.row.entity, nextRowColumn.col);
      }
    }

    /*
        On grid initialization launch different watchers that handle scrollbar visibility check
     */
    function initScrollbarVisibilityWatchers () {
      // watch for the split-pane height change
      ctrl.watchers.push($scope.$watch(function () {
        return ctrl.tableHeight;
      }, calculateScrollbarVisibility));
      // watch for window resize
      angular.element($window).on("resize", calculateScrollbarVisibility);
    }

    /*
        When we edit any of the cells then check what type of cell was edited and call necessary services and handle loading states etc
     */
    function onCellEdited (rowEntity, colDef, newValue, oldValue, evt) {
      if (newValue) newValue.loading = true;
      calculateEllipsis();
      switch (colDef.field) {
        case "Description":
          detailedTransactionsTableService.saveRow(rowEntity, ctrl.invoice).then(function (res) {
            newValue.loading = false;
          });
          break;
        case "AccountingDate":
          detailedTransactionsTableService.saveDate(rowEntity).then(function (res) {
            newValue.loading = false;
          });
          break;
        case "Comment":
          detailedTransactionsTableService.saveComment(rowEntity).then(function (res) {
            newValue.loading = false;
          });
          break;
        case "SumWithoutVat":
        case "Total":
        case "VatCode":
          if (newValue) {
            var vatPercentage = rowEntity.VatCode.VatRate,
                newSum = vatPercentage === 0 ? rowEntity.Total.value : rowEntity.Total.value * 100 / (vatPercentage + 100),
                newVat = vatPercentage === 0 ? 0 : rowEntity.Total.value - newSum;
              /**
               * TODO: these calculations should be done on the BE side
               */
            if (colDef.field === "SumWithoutVat") {
              rowEntity.VAT.value = ((Number(rowEntity.SumWithoutVat.value) * (vatPercentage + 100) / 100) - Number(rowEntity.SumWithoutVat.value)).toFixed(2);
              rowEntity.Total.value = (Number(rowEntity.SumWithoutVat.value) + Number(rowEntity.VAT.value)).toFixed(2);
            } else if (colDef.field === "Total") {
              var sumWithoutVat = vatPercentage === 0 ? rowEntity.Total.value : ((100 * rowEntity.Total.value) / (100 + vatPercentage));
              var vat = vatPercentage === 0 ? 0 : rowEntity.Total.value - sumWithoutVat;
              rowEntity.SumWithoutVat.value = Number(Math.round(sumWithoutVat + "e2") + "e-2");
              rowEntity.VAT.value = Number(Math.round(vat + "e2") + "e-2");
            } else if (colDef.field === "VatCode") {
              rowEntity.SumWithoutVat.value = Number(Math.round(newSum + "e2") + "e-2");
              rowEntity.VAT.value = Number(Math.round(newVat + "e2") + "e-2");
            }
          } else {
              rowEntity.VatCode = null;
          }
          if (colDef.field === "VatCode") {
            detailedTransactionsTableService.saveVatCode(rowEntity, ctrl.invoice).then(
                function (res) {
                    ctrl.tableOptions.data.forEach(function (row) {
                        if (row.Id === res.Id) {
                            row.Id = res.Id;
                            row.SumWithoutVat.value = res.SumWithoutVat;
                            row.VAT.value = res.VAT;
                            row.VatCode = res.VatCode;
                            row.VatCodeId = res.VatCodeId
                        }
                    });
                    rowEntity.Id = res.Id;
                    rowEntity.SumWithoutVat.value = res.SumWithoutVat;
                    rowEntity.VAT.value = res.VAT;
                    rowEntity.VatCode = res.VatCode;
                    rowEntity.VatCodeId = res.VatCodeId;
                }).then(
                function () {
                    canMergeRows();
                    if (newValue) newValue.loading = false;
                }
            );
          } else {
              detailedTransactionsTableService.saveRow(rowEntity, ctrl.invoice).then(function (res) {
                  canMergeRows();
                  newValue.loading = false;
              });
          }
          break;
        case "Account":
          detailedTransactionsTableService.saveAccount(newValue ? newValue.Id : null, rowEntity.Id).then(function (res) {
            rowEntity.Description.value = res.data.Description;
            // add additional columns that come from the selected account
            detailedTransactionsTableService.addColumnBasedOnCustomFields(invoiceService.addCustomCostObjectivesBasedOnAccount(newValue, []), ctrl.tableOptions.columnDefs, rowEntity);
            getTransactionRowsData();
            $scope.gridApi.core.refresh();
            newValue.loading = false;
          }).catch(function () {
            newValue.loading = false;
          });
          break;
        default:
          if (newValue) {
              newValue.Id = oldValue ? oldValue.Id : null; // we have to set customField Id from old one, because the object selected from typeahead doesn't have Id and BE needs it
              newValue.original = oldValue ? oldValue.original : null;
          } else {
            newValue = {
                DimensionId: null,
                CustomCostObjective: oldValue.CustomCostObjective
            }
          }
          detailedTransactionsTableService.saveCustomField(newValue, rowEntity.Id).then(function (response) {
            newValue.Id = response.Id;
            detailedTransactionsTableService.addColumnBasedOnCustomFields([response], ctrl.tableOptions.columnDefs, rowEntity);
            $rootScope.$emit("calculateEmptyMandatoryFields"); // check if emptyMandatoryFieldCount changed
            $scope.gridApi.core.refresh();
            newValue.loading = false;
          }).catch(function () {
            newValue.loading = false;
          });
          break;
      }
    }

    function deleteRow (row) {
      deleteRows([row.Id]);
    }

    function deleteRows(rowsIds) {
      ctrl.tableDataLoading = true;
      webServices.deleteTransactionRow(rowsIds).then(
        function () {
          getTransactionRowsData();
          ctrl.tableDataLoading = false;
        }
      )
    }

    /*
        Open transaction rows splitting modal
        // TODO Refactor this modal into a component
     */
    function openSplitModal (row) {
      var modalInstance = $uibModal.open({
        templateUrl: "app/components/transactionRows/ds-transaction-row-split.html",
        controller: "DsTransactionRowSplitController",
        windowClass: "transactions-split-modal",
        backdrop: "static",
        resolve: {
          param: function () {
            return {
              "row": detailedTransactionsTableService.unparseAccountantRows(row, ctrl.invoice),
              "invoice": ctrl.invoice
            };
          }
        }
      });
      modalInstance.result.then(function (result) {
        if (result) {
          //TODO duplicate from invoiceConfirmationController. Move this to a service
          var apiObject = {
            "AccountDistributionItem": result.transactionRow,
            "SplitBy": result.splitBy,
            "SplitRows": result.splitRows
          };
          apiObject.AccountDistributionItem.transactionRowsForm = null;
          apiObject.AccountDistributionItem.TransactionRowsDimensions = angular.forEach(_.filter(apiObject.AccountDistributionItem.TransactionRowsDimensions, function (item) {
            return item.Id > 0;
          }), function (dimension) {
            dimension.childTransactionRowsForm = null;
            dimension.childTypeTransactionRowsForm = null;
            dimension.Dimension = null;
            dimension.CustomCostObjective = null;
            return dimension;
          });
          ctrl.tableDataLoading = true;
          webServices.splitTransactionRow(apiObject).then(function (response) {
            if (response.data) {
              getTransactionRowsData();
              ctrl.tableDataLoading = false;
            } else {
              notifyService.error("component.transactionRow.split.errorDuringSplit", "controller.invoiceConfirmationController.Error", true);
            }
          }).catch(function (err) {
            notifyService.error("component.transactionRow.split.errorDuringSplit", "controller.invoiceConfirmationController.Error", true);
          });
        }
      });
    }

    /*
        Cancel adding the new column to table. Clears and closes the popover
     */
    function cancelNewColumnAdd () {
      ctrl.addNewColumnPopover.open = false;
      // wait with clearing the values until popover is gone
      setTimeout(function () {
        ctrl.addNewColumnPopover.newColumnValue = null;
        ctrl.addNewColumnPopover.invalid = false;
      });
    }

    /*
        Using the selected dimension from typeahead, create a new model value object
     */
    function updateDimension (item) {
      // find the dimension object
      var customCostObjective = _.find(detailedTransactionsTableService.getCustomCostObjectives(),
        function (cft) {
          return cft.Id === item.CustomCostObjectiveId;
        });
      return {
        Dimension: item,
        CustomCostObjective: customCostObjective,
        CustomCostObjectiveId: customCostObjective.Id,
        DimensionId: item.Id,
        IsNew: customCostObjective.IsNew
      };
    }

    /*
        Copys the item value to all the cells in the column
     */
    function copyColumnToAllRows (item, col) {
      if (item) {
        ctrl.tableDataLoading = true;
        var accountingRowDTO = {};
        switch (col.field) {
          case "VatCode":
            accountingRowDTO.VatCodeId = item.Id;
            break;
          case "Account":
            accountingRowDTO.AccountId = item.Id;
            break;
          case "Comment":
            accountingRowDTO.Comment = item;
            break;
          case "AccountingDate":
            accountingRowDTO.AccountingDate = item ? formatDate(item, 'yyyy-MM-dd') : null;
            break;
          default:
            accountingRowDTO.TransactionRowsDimensions = [{CustomCostObjectiveId: item.CustomCostObjectiveId, DimensionId: item.Id}];
            break;
        }

        detailedTransactionsTableService.copyToAllTransactionRows(accountingRowDTO, ctrl.invoice.Id).then(function (res) {
          getTransactionRowsData();
        });
      }
    }

    function selectNewColumn (item, evt) {
        ctrl.addNewColumnPopover.invalid = false;
        ctrl.addNewColumnPopover.selectedNewColumn = item;
        evt.stopPropagation();
    }

    /*
        Add the selected dimension to the table as a new column
     */
    function addNewColumn (newColumn) {
      //TODO use newColumnValue instead of general popovervalue
      if (!ctrl.addNewColumnPopover.selectedNewColumn) {
        ctrl.addNewColumnPopover.invalid = true;
      } else {
        ctrl.addNewColumnPopover.invalid = false;
        ctrl.tableReloading = true;
        // add new column to table
        // ctrl.addNewColumnPopover.newColumnValue.Value can be either AccountingDate, Comment or Custom dimension
        var newColDef = new detailedTransactionsTableService.GetNewColumnDefinitionObject(ctrl.addNewColumnPopover.selectedNewColumn);
        ctrl.tableOptions.columnDefs.push(newColDef);
        calculateEllipsis(); // make sure ellipsis are calculated when we add a new column
        ctrl.tableOptions.data = detailedTransactionsTableService.addMissingDimensionsToRows(ctrl.tableOptions.columnDefs, ctrl.tableOptions.data);
        ctrl.tableOptions.columnDefs = detailedTransactionsTableService.sortColumnDefs(ctrl.tableOptions.columnDefs);
        ctrl.addNewColumnPopover.open = false;
        // scroll column into view
        // TODO this timeout is a bad solution but we needed to wait when the column has been added to the table
        setTimeout(function () {
          ctrl.tableReloading = false;
          ctrl.gridApi.grid.scrollTo(null, _.find(ctrl.tableOptions.columnDefs, function (col) {
            return col.field === ctrl.addNewColumnPopover.selectedNewColumn.Value;
          }));
          ctrl.addNewColumnPopover.selectedNewColumn = null;
        }, 300);
      }
    }

    /*
        Remove column and also tells BE to remove this type value from all the rows.
     */
    function removeColumn (col) {
      ctrl.tableReloading = true;
      var accountingRowDTO = {};
      // remove column
      ctrl.tableOptions.columnDefs = _.reject(ctrl.tableOptions.columnDefs, function (columnDef) {
        return columnDef.field === col.field;
      });
      setTimeout(() => ctrl.tableReloading = false)
      // build AccountingRowDTO with null values
      switch (col.field) {
        case "Comment":
          accountingRowDTO.Comment = "";
          break;
        case "AccountingDate":
          accountingRowDTO.AccountingDate = null;
          break;
        default:
          accountingRowDTO.TransactionRowsDimensions = [{CustomCostObjectiveId: col.colDef.customCostObjectiveId, DimensionId: null}];
          break;
      }
      detailedTransactionsTableService.copyToAllTransactionRows(accountingRowDTO, ctrl.invoice.Id).then(function (res) {
        getTransactionRowsData();
      });
    }

    /*
        Add new row to table
     */
    function addNewRow (remainingSum) {
      if (Math.abs(remainingSum) > 0) {
        remainingSum = typeof remainingSum === "string" ? remainingSum.replace('+', '') : remainingSum;
        ctrl.tableDataLoading = true;
        var newRow = {
          Id: -1,
          IsNew: true,
          SumWithoutVat: remainingSum,
          Total: remainingSum,
          InvoiceId: ctrl.invoice.Id,
          Description: $filter("translate")("component.transactionRows.newRowDescription")
        };
        // add new row
        webServices.addNewTransactionRow(newRow).then(function (response) {
          // get new data for table
          getTransactionRowsData().then(function (res) {
            setTimeout(function () {
              ctrl.gridApi.grid.scrollTo(_.last(ctrl.tableOptions.data), null);
            });
          });
        });
      }
    }

    /*
        Check if cell is mandatory
     */
    function isCellMandatory (col, row, alsoCheckVisibility) {
      var rowEntity = row.entity ? row.entity : row; // depends on the method, but in some cases the param row is already and entity
      // if (_.find($rootScope.accountDistributionItemCustomCostObjectives, function (t) {
      if (_.find(detailedTransactionsTableService.getCustomCostObjectives(), function (t) {
        return t.Id === col.colDef.customCostObjectiveId && ((!alsoCheckVisibility && t.IsMandatory) || (alsoCheckVisibility && (t.IsMandatory || t.IsVisible)));
      })) {
        // if cell is part of the mandatory fields set in the company settings
        return true;
      } else if (rowEntity.Account && _.find(rowEntity.Account.AdditionalFields, function (a) {
        return a.CustomCostObjectiveId === col.colDef.customCostObjectiveId;
      })) {
        // if cell is part of the mandatory fields set by the account selection
        return true;
      } else {
        return false;
      }
    }

    /*
        Check if this column has any rows that have IsMandatory or IsVisible cells or if columns are AccountingDate or Comment
     */
    function isColumnRemovable (col) {
      if (col.field === "AccountingDate" || col.field === "Comment") {
        // allow removal forl AccountingDate and Comment columns
        return true;
      } else if (col.field === "Account" || col.field === "VatCode") {
        // don't allow removal for Account and VatCode columns
        return false;
      } else if (!!_.find(ctrl.tableOptions.data, function (row) {
        return isCellMandatory(col, row, true);
      })) {
        // don't allow removal for columns that have cells that are either IsMandatory or IsVisible
        return false;
      } else {
        return true;
      }
    }

    /*
        Copy whole transactionRow to rows below
     */
    function copyContentToRowsBelow (rowEntity) {
      ctrl.tableDataLoading = true;
      detailedTransactionsTableService.copyToAllTransactionRows(
        detailedTransactionsTableService.unparseAccountantRows(rowEntity, ctrl.invoice),
        ctrl.invoice.Id,
        rowEntity.OrderNo
      ).then(function (res) {
        getTransactionRowsData();
      });
    }

    /*
        Callback is used mainly because we use this function also as a column processor and it has to return the full column list
     */
    function calculateEllipsis (callback) {
      setTimeout(function () {
        $rootScope.$emit("buildEllipsis"); // emit event so we can recalculate the cell ellipsis
      });
      return callback;
    }

    /*
        Check if the dynamic right side of the table has vertical/horizontal scrollbars visible
        Callback is used mainly because we use this function also as a column processor and it has to return the full column list
     */
    function calculateScrollbarVisibility (callback) {
      if (ctrl.viewport) {
        setTimeout(function () {
          $window.requestAnimationFrame(function () {
            ctrl.tableHasHorizontalScrollbar = ctrl.viewport.scrollWidth > ctrl.viewport.clientWidth;
            ctrl.tableHasVerticalScrollbar = ctrl.viewport.scrollHeight > ctrl.viewport.clientHeight;
          });
        });
      }
      return callback;
    }
  }

  ServiceDecorator.$inject = [
    "$provide",
    "uiGridConstants"
  ];

  function ServiceDecorator ($provide, uiGridConstants) {
    $provide.decorator("uiGridEditService", function ($delegate) {
      // override isStartEditKey in uiGridEditService to add a rule, that we don't start editing on DEL key
      $delegate.isStartEditKey = function (evt) {
        if (evt.metaKey ||
          evt.keyCode === uiGridConstants.keymap.ESC ||
          evt.keyCode === uiGridConstants.keymap.SHIFT ||
          evt.keyCode === uiGridConstants.keymap.CTRL ||
          evt.keyCode === uiGridConstants.keymap.ALT ||
          evt.keyCode === uiGridConstants.keymap.WIN ||
          evt.keyCode === uiGridConstants.keymap.CAPSLOCK ||
          evt.keyCode === uiGridConstants.keymap.LEFT ||
          (evt.keyCode === uiGridConstants.keymap.TAB && evt.shiftKey) ||
          evt.keyCode === uiGridConstants.keymap.RIGHT ||
          evt.keyCode === uiGridConstants.keymap.TAB ||
          evt.keyCode === uiGridConstants.keymap.UP ||
          (evt.keyCode === uiGridConstants.keymap.ENTER && evt.shiftKey) ||
          evt.keyCode === uiGridConstants.keymap.DEL ||
          evt.keyCode === uiGridConstants.keymap.DOWN ||
          evt.keyCode === uiGridConstants.keymap.ENTER) {
          return false;
        }
        return true;
      };
      return $delegate;
    });
  }
})();
