import React from 'react';
import { TFunction } from 'i18next';
import { isEqual } from 'lodash-es';
import Big from 'big.js';

import { formatDate } from '../../../../../common/utils/formatters';
import { Typography } from '../../../../../components/Typography';
import { InvoiceDTO, InvoiceType } from '../../../../../services/types/ApiTypes';
import { createDataId } from '../../../../../common/utils/dataId';
import PropertyListItem, { getStringOrDashes } from '../../../../../components/PropertyListItem/PropertyListItem';
import StampLabel, { StampType } from '../../../../../components/StampLabel/StampLabel';
import constants from '../../../../../common/constants/appConstants';
import { getStatusStamptype, formatMoneyToShowSeparators } from '../utils';
import DefaultCurrency from '../../../../../components/DefaultCurrency/DefaultCurrency';
import { HeaderPlaceholder } from '../../../../purchase-orders-add/components/Header/HeaderPlaceholder';
import { PropertyListItemWrapper } from './PropertyListItemWrapper';

const BasicInfoViewMode = ({
    dataId,
    defaultCurrency,
    invoice,
    isErpVisible,
    isNewSupplier,
    isInvoiceLoading,
    t,
}: {
    dataId: string;
    defaultCurrency?: string;
    invoice: InvoiceDTO;
    isErpVisible: boolean;
    isNewSupplier: boolean;
    isInvoiceLoading: boolean;
    t: TFunction;
}) => (
    <>
        <div className="invoice-header__head">
            <div className="invoice-header__head--row">
                <div className="invoice-header__head--left">
                    <ul>
                        <PropertyListItemWrapper isLoading={Boolean(!invoice?.Id || isInvoiceLoading)} label={t('component.additionalInfo.supplier')} dataId={dataId}>
                            <Typography variant="h1" element="span">
                                {invoice?.SupplierName}
                            </Typography>
                        </PropertyListItemWrapper>
                    </ul>
                </div>
                <div className="invoice-header__head--right">
                    <ul>
                        <PropertyListItem
                            className="invoice-header__invoice-number"
                            dataId={dataId}
                            alignRight
                            label={t('component.additionalInfo.invoiceNumber')}
                            value={invoice?.Number}
                            isBiggerFontText
                            showPlaceholder={!invoice?.Id || isInvoiceLoading}
                            hideOverflow={true}
                        />
                        {isErpVisible && (
                            <PropertyListItem dataId={dataId} alignRight label={t('component.invoicesTable.erpId')} value={invoice?.ErpId} showPlaceholder={!invoice?.Id || isInvoiceLoading} />
                        )}
                    </ul>
                </div>
            </div>
            <div className="invoice-header__head--labels-row">
                {invoice?.Id && !isInvoiceLoading ? (
                    <p data-id={createDataId(dataId, 'description')} className="invoice-description">
                        {invoice?.Description}
                    </p>
                ) : (
                    <HeaderPlaceholder class={'invoice-header'} />
                )}
                {invoice?.Id && !isInvoiceLoading ? (
                    <div data-id={createDataId(dataId, 'stamp-labels')} className="stamp-labels">
                        {invoice?.InvoiceType?.Type === InvoiceType.Expense && <StampLabel type={StampType.INFO} text={t('views.invoice.partials.invoiceInformation.expense')} />}
                        {isNewSupplier && <StampLabel type={StampType.ACTIVE} text={t('views.global.NewSupplier')} />}
                        {invoice?.IsDuplicate && <StampLabel type={StampType.ALARM} text={t('views.invoice.partials.invoiceInformation.Duplicate')} />}
                        {invoice?.IsCredit && <StampLabel type={StampType.ALARM} text={t('views.invoice.partials.invoiceInformation.Credit')} />}
                        <StampLabel type={getStatusStamptype(invoice)} text={t(constants.getInvoiceStatusTranslateKey(invoice?.Status))} />
                    </div>
                ) : (
                    <HeaderPlaceholder class={'invoice-header'} />
                )}
            </div>
        </div>
        <div className="invoice-header__fields">
            <ul>
                <PropertyListItem
                    dataId={dataId}
                    label={t('component.additionalInfo.supplierRegNumber')}
                    value={invoice?.SupplierRegCode}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                    hideOverflow={true}
                />
                <PropertyListItem
                    dataId={dataId}
                    label={t('component.additionalInfo.supplierVatNumber')}
                    value={invoice?.Supplier?.VatCode}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                    hideOverflow={true}
                />
                <PropertyListItem
                    dataId={dataId}
                    label={t('component.additionalInfo.supplierErpCode')}
                    value={invoice?.SupplierCode}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                    hideOverflow={true}
                />
            </ul>
            <ul>
                <PropertyListItem
                    className={'invoice-header__beneficiary'}
                    dataId={dataId}
                    label={t('component.additionalInfo.beneficiary')}
                    value={invoice?.Beneficiary}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                    hideOverflow={true}
                />
                <PropertyListItem
                    dataId={dataId}
                    label={t('component.additionalInfo.payToAccount')}
                    value={invoice?.PayToAccount}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                    hideOverflow={true}
                />
                <PropertyListItem
                    dataId={dataId}
                    label={t('component.additionalInfo.invoiceReferenceNumber')}
                    value={invoice?.ReferenceNumber}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                    hideOverflow={true}
                />
                <PropertyListItem
                    dataId={dataId}
                    label={t('component.additionalInfo.ContractNumber')}
                    value={invoice?.ContractNumber}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                    hideOverflow={true}
                />
            </ul>
            <ul>
                <PropertyListItem
                    dataId={dataId}
                    label={t('component.additionalInfo.buyerContact.invoiceDate')}
                    value={formatDate(invoice?.InvoiceDate)}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                />
                <PropertyListItem dataId={dataId} label={t('component.additionalInfo.buyerContact.dueDate')} value={formatDate(invoice?.DueDate)} showPlaceholder={!invoice?.Id || isInvoiceLoading} />
                <PropertyListItem
                    dataId={dataId}
                    label={t('component.additionalInfo.buyerContact.accountingDate')}
                    value={formatDate(invoice?.AccountingDate)}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                />
                {!!invoice?.InvoicePaidDateTime && (
                    <PropertyListItem
                        dataId={dataId}
                        label={t('component.additionalInfo.invoicePaidDateTime')}
                        value={formatDate(invoice.InvoicePaidDateTime)}
                        showPlaceholder={!invoice?.Id || isInvoiceLoading}
                    />
                )}
                <PropertyListItem
                    dataId={dataId}
                    label={t('component.additionalInfo.createdDateTime')}
                    value={formatDate(invoice?.ImportedDate, 'dd.MM.yyyy HH:mm:ss')}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                />
            </ul>
            <ul>
                <PropertyListItem
                    dataId={dataId}
                    alignRight
                    label={t('component.additionalInfo.sumWithoutVat')}
                    value={invoice ? `${formatMoneyToShowSeparators(invoice?.SumWithoutVat, true)} ${getStringOrDashes(invoice?.Currency)}` : null}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                />
                <PropertyListItem
                    dataId={dataId}
                    alignRight
                    label={t('component.additionalInfo.VAT')}
                    value={invoice ? `${formatMoneyToShowSeparators(invoice?.Vat, true)} ${getStringOrDashes(invoice?.Currency)}` : null}
                    showPlaceholder={!invoice?.Id || isInvoiceLoading}
                />

                <PropertyListItemWrapper dataId={dataId} label={t('component.additionalInfo.TotalAmountWithVat')} alignRight>
                    <Typography variant="h1" element="span">
                        {invoice && `${formatMoneyToShowSeparators(invoice?.TotalAmountWithVat, true)} ${getStringOrDashes(invoice?.Currency)}`}
                    </Typography>
                </PropertyListItemWrapper>

                {defaultCurrency && defaultCurrency !== invoice?.Currency && invoice?.DefaultCurrencyRate > 0 && (
                    <DefaultCurrency
                        DefaultCurrencyRate={invoice?.DefaultCurrencyRate}
                        CurrencyRateDate={formatDate(invoice?.CurrencyRateDate || invoice.InvoiceDate)}
                    >{`~ ${formatMoneyToShowSeparators(new Big(invoice.TotalAmountWithVat).div(invoice.DefaultCurrencyRate).toString(), true)} ${defaultCurrency}`}</DefaultCurrency>
                )}
            </ul>
        </div>
    </>
);

// TODO: for memoization to work, provide the same prop functions from parent (useCallback)
export default React.memo(BasicInfoViewMode, isEqual);
