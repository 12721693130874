import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { GlobalState } from '../../rootReducer';

import { DispatchProps, EditSupplierModal, Props } from './EditSupplierModal';
import { addOrUpdateSupplier, showEditSupplierModal } from './EditSupplierModalActions';
import { selectActiveSupplier, selectIsEditSupplierModalOpen, selectCurrentCompanyCountry } from './EditSupplierModalReducer';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        activeSupplier: selectActiveSupplier(state),
        isModalOpen: selectIsEditSupplierModalOpen(state),
        currentCompanyCountry: selectCurrentCompanyCountry(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    saveSupplier: addOrUpdateSupplier,
    setModalOpen: showEditSupplierModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditSupplierModal));
