import {SearchType, SortDirection} from "../../src/services/types/ApiTypes";
import api from '../../src/services/ApiServices';

function longsToStrings(response) {
    var newResponse = response.replace(new RegExp('"ItemPrice":(-?[0-9.]+),',"g"),'"ItemPrice":"$1",');
    newResponse = newResponse.replace(new RegExp('"ItemAmount":(-?[0-9.]+),',"g"),'"ItemAmount":"$1",');
    newResponse = newResponse.replace(new RegExp('"Vat":(-?[0-9.]+),',"g"),'"Vat":"$1",');
    newResponse = newResponse.replace(new RegExp('"TotalAmountWithVat":(-?[0-9.]+),',"g"),'"TotalAmountWithVat":"$1",');
    newResponse = newResponse.replace(new RegExp('"SumWithoutVat":(-?[0-9.]+),',"g"),'"SumWithoutVat":"$1",');
    return JSON.parse(newResponse);
}
function stringToNumber(response) {
    var newResponse = JSON.stringify(response);
    newResponse = newResponse.replace(new RegExp('"ItemPrice":"(-?[0-9.]+)",',"g"),'"ItemPrice":$1,');
    newResponse = newResponse.replace(new RegExp('"ItemAmount":"(-?[0-9.]+)",',"g"),'"ItemAmount":$1,');
    newResponse = newResponse.replace(new RegExp('"Vat":"(-?[0-9.]+)",',"g"),'"Vat":$1,');
    newResponse = newResponse.replace(new RegExp('"TotalAmountWithVat":"(-?[0-9.]+)",',"g"),'"TotalAmountWithVat":$1,');
    newResponse = newResponse.replace(new RegExp('"SumWithoutVat":"(-?[0-9.]+)",',"g"),'"SumWithoutVat":$1,');
    newResponse = newResponse.replace(new RegExp('"Total":"(-?[0-9.]+)",',"g"),'"Total":$1,');
    return newResponse;
}
  
(function () {
    "use strict";

    angular
        .module("dstreamApp.services")
        .factory("webServices", webServices);

    webServices.$inject = [
        "$http",
        "localStorageService",
        "$q"
    ];

    /*
            Handle all the API requests
         */
    function webServices($http, localStorageService, $q) {
        var baseUrl = "../WebApi/api/";
        var webService = {};
        var queue = [];

        function execNext() {
            var task = queue[0];
            $http(task.c).then(function (data) {
                queue.shift();
                task.d.resolve(data);
                if (queue.length > 0) execNext();
            }, function (err) {
                task.d.reject(err);
            });
        }

        function queueFactory(config) {
            var d = $q.defer();
            queue.push({ c: config, d: d });
            if (queue.length === 1) execNext();
            return d.promise;
        }

        /// //
        // User webservices
        /// /
        webService.getUser = function (id) {
            return $http.get(baseUrl + "user/GetUser/" + id);
        };
        webService.getProductItems = function (name) {
            var encName = encodeURIComponent(name || '');
            return $http.get(baseUrl + "InvoiceRow/GetProductItems?name=" + encName);
        };

        webService.getUsers = function (searchParams) {
            return $http.post(baseUrl + "user/GetUsers", searchParams);
        };

        webService.addUser = function (user) {
            return $http.post(baseUrl + "user/AddUser", user);
        };

        webService.editUser = function (user) {
            return $http.post(baseUrl + "user/EditUser", user);
        };
        
        webService.boGetUserCompanies = function (name) {
            const searchUrl = baseUrl + "BO/GetUserCompanies";
            return queueFactory({ method: "GET", url: name ? searchUrl + '?companyName=' + name : searchUrl });
        };

        // This is not used anymore (all goes through UserActions.ts)
        webService.getCurrentUser = function () {
            return queueFactory({ method: "GET", url: baseUrl + "User/GetCurrentUser" });
        };

        webService.boChangePassword = function (oldpassword, newpassword) {
            return $http.post(baseUrl + "User/ChangeUserPassword?oldpassword=" + oldpassword + "&newpassword=" + newpassword);
        };

        webService.changeUserLanguage = function (newLanguage) {
            return $http.post(baseUrl + "User/ChangeUserLanguage?newLanguage=" + newLanguage);
        };

        webService.bochangeUserLastCompany = function (companyGuid) {
            return queueFactory({ method: "POST", url: baseUrl + "BO/ChangeUserLastCompany?companyGuid=" + companyGuid });
        };

        webService.exportTransactionRowsToXls = function (invoiceId) {
            delete $http.defaults.headers.common["X-Requested-With"];
            return $http.post(baseUrl + "TransactionRow/ExportTransactionRowsToXls?invoiceId=" + invoiceId, null, {responseType: 'blob'});
        };

        webService.exportTransactionRowsWithFormulasToXls = function (invoiceId) {
            delete $http.defaults.headers.common["X-Requested-With"];
            return $http.post(baseUrl + "TransactionRow/ExportTransactionRowsWithFormulasToXls?invoiceId=" + invoiceId, null, {responseType: 'blob'});
        }

        webService.exportTransactionRowsToCsv = function (invoiceId) {
            return $http.post(baseUrl + "TransactionRow/ExportTransactionRowsToCSV?invoiceId=" + invoiceId);
        };

        /// //
        // GroupMember webservices
        /// /
        webService.getGroupMemberByUserGuid = function (userGuid) {
            return queueFactory({ method: "GET", url: baseUrl + "GroupMember/GetGroupMember?userGuid=" + userGuid });
        };

        webService.getCommonGroupMemberByGroupMemberId = function (groupMemberId) {
            return queueFactory({ method: "GET", url: baseUrl + "GroupMember/GetCommonGroupMember?groupMemberId=" + groupMemberId });
        };

        webService.getGroupMembers = function (searchParams) {
            return $http.post(baseUrl + "GroupMember/GetGroupMembers", searchParams);
        };

        webService.getCompanyGroupMembers = function (companyGuid) {
            return $http.get(baseUrl + "GroupMember/GetCompanyGroupMembers?boCompanyGuid=" + companyGuid);
        };

        webService.getGroupMembersByName = function (type, name) {
            var encName = encodeURIComponent(name || '');
            return $http.get(baseUrl + "GroupMember/GetByName?type=" + type + "&name=" + encName);
        };

        webService.getGroupMembersByCompanyId = function () {
            return $http.get(baseUrl + "GroupMember/GetGroupMembers");
        };

        webService.getArchiveCompanyGroupMembers = function () {
            return $http.get(baseUrl + "GroupMember/GetArchiveCompanyGroupMembers");
        };
        webService.getTransactionRowsExtendedInfoList = function (id) {
            return $http.get(baseUrl + "TransactionRow/GetTransactionRowsExtendedInfoList/" + id);
        };
        webService.mergeAccountingRowsByTransactionRowExtension = function (invoiceId, groupingMetaInfoDTO) {
            return $http.post(baseUrl + "Invoice/MergeAccountingRowsByTransactionRowExtension/" + invoiceId, groupingMetaInfoDTO);
        };

        webService.addGroupMember = function (groupMember) {
            return $http.post(baseUrl + "GroupMember/AddGroupMember", groupMember);
        };

        webService.editGroupMember = function (groupMember) {
            return $http.post(baseUrl + "GroupMember/EditGroupMember", groupMember);
        };

        webService.deleteGroupMember = function (id) {
            return $http.post(baseUrl + "GroupMember/Delete?", id);
        };

        webService.checkGroupMemberExists = function (personalId, groupMemberId) {
            return $http.get(baseUrl + "GroupMember/CheckGroupMemberExists?personalId=" + personalId + "&groupMemberId=" + groupMemberId);
        };

        webService.exportUsersToXls = function () {
            return $http.get(baseUrl + "GroupMember/ExportUsersToXls", { responseType: 'arraybuffer' });
        };

        webService.getSuppliers = function (searchParams) {
            return $http.post(baseUrl + "Supplier/GetSuppliers", searchParams);
        };

        /// //
        // Company webservices
        /// /
        webService.getCompanies = function (searchParams) {
            return $http.post(baseUrl + "company/GetCompanies", searchParams);
        };

        webService.getCompanyByNameOrRegistrationCode = function (part) {
            var encName = encodeURIComponent(part || '');
            return $http.get(baseUrl + "company/GetCompaniesByNameOrRegistrationCode?part=" + encName);
        };

        webService.getCompany = function (supplierId) {
            return $http.get(baseUrl + "company/GetCompany?supplierId=" + supplierId);
        };

        webService.getCustomerBasedCompany = function () {
            return $http.get(baseUrl + "company/GetCustomerBasedCompany");
        };

        webService.getCurrentCompany = function () {
            return $http.get(baseUrl + "company/GetCurrentCompany");
        };

        webService.getCurrentCompanyLogo = function () {
            return $http.get(baseUrl + "company/GetCurrentCompanyLogo");
        };

        webService.getCompanySettings = function (companyGuid) {
            return queueFactory({
                method: "GET",
                url: baseUrl + `company/GetCompanySettings?companyGuid=${companyGuid}`
            });
        };
        webService.getCurrentCompanyInvoiceCustomFields = function () {
            return queueFactory({
                method: "GET",
                url: baseUrl + "company/GetInvoiceCustomFieldsWithoutItems"
            });
        };
        webService.getAvailableIsibFunds = function (model) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "TransactionRow/GetAvailableIsibFunds",
                data: model
            });
        };

        webService.getInvoiceCustomFieldItems = function (field) {
            return queueFactory({
                method: "GET",
                url: baseUrl + "company/GetInvoiceCustomFieldItems/" + field.Id
            });
        };

        webService.addCompany = function (company) {
            return $http.post(baseUrl + "company/AddCompany", company);
        };

        webService.deleteCompany = function (id) {
            return $http.post(baseUrl + "company/DeleteCompany", id);
        };

        webService.updateCompany = function (company) {
            return $http.post(baseUrl + "company/UpdateCompany", company);
        };

        webService.checkErpCode = function (code, companyId) {
            return $http.get(baseUrl + "company/CheckErpCode?code=" + code + "&companyId=" + companyId);
        };
        webService.AreTransactionRowsValid = function (id) {
            return $http.get(baseUrl + "invoice/AreTransactionRowsValid/" + id);
        };
        webService.AreTransactionRowsValidPenultimate = function (id) {
            return $http.get(baseUrl + "invoice/AreTransactionRowsValidPenultimate/" + id);
        };
        webService.AreCustomFieldsValid = function (id) {
            return $http.get(baseUrl + "invoice/AreCustomFieldsValid/" + id);
        };      
        webService.GetRowHistory = function (id) {
            return $http.get(baseUrl + "TransactionRow/GetRowHistory/" + id);
        };

        webService.addCompanySettings = function (companyGuid, companySettings) {
            return $http.post(baseUrl + `company/AddCompanySettings?companyGuid=${companyGuid}`, companySettings);
        };

        webService.addRelatedCompany = function (companyId, relatedCompanyId) {
            return $http.get(baseUrl + "company/AddRelatedCompany?companyId=" + companyId + "&relatedCompanyId=" + relatedCompanyId);
        };

        webService.deleteRelatedCompany = function (companyId, relatedCompanyId) {
            return $http.get(baseUrl + "company/DeleteRelatedCompany?companyId=" + companyId + "&relatedCompanyId=" + relatedCompanyId);
        };

        webService.getCompaniesByName = function (name) {
            var encName = encodeURIComponent(name || '');
            return $http.get(baseUrl + "company/GetByName/?name=" + encName);
        };

        webService.getAllCompaniesFromTheSameOrganizationByName = function (name) {
            var encName = encodeURIComponent(name || '');
            return $http.get(baseUrl + "company/GetAllCompaniesFromTheSameOrganizationByName/?name=" + encName);
        };

        webService.getCompanyData = function () {
            return $http.get(baseUrl + "company/GetCompanyData");
        };

        webService.getCompanyUsers = function (searchParams) {
            return $http.post(baseUrl + "company/GetCompanyUsers", searchParams);
        };

        webService.uploadCSVFileToCompanies = function (file) {
            return $http.post(baseUrl + "company/UploadCSVFile", file);
        };

        webService.downloadFileById = function (id, fromArchive, withFrontPage, language) {
            const date = new Date();
            const offsetInHours = date.getTimezoneOffset() / 60;
            return $http({
                method: "POST",
                url: baseUrl + "File/DownloadFile/" + id + "?fromArchive=" + !!fromArchive + "&withFrontPage=" + withFrontPage + "&language=" + language + "&offsetInHours=" + offsetInHours,
                responseType: "blob"
            });
        };

        /// //
        // File webServices
        /// /
        webService.downloadInvoiceFileByUrl = function (fileUrl, fromArchive) {
            delete $http.defaults.headers.common["X-Requested-With"];
            return $http({
                method: "POST",
                url: fileUrl + "?fromArchive=" + !!fromArchive,
                responseType: "blob"
            });
        };

        /// //
        // Invoice webservices
        /// /

        webService.getAvailableCustomizationFields = function (searchParams = undefined ) { // searchParams of InvoiceCustomizationFieldsSearchParams type
            return $http.get(baseUrl + "Invoice/GetAvailableCustomizationFields", {params: searchParams});
        };

        webService.getInvoicesSmall = function (searchParams) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "invoice/GetInvoicesSmall",
                data: searchParams
            });
        };

        webService.getInvoicesSmallNoQueue = function (searchParams) {
            return $http.post(baseUrl + "invoice/GetInvoicesSmall", searchParams);
        };

        webService.GetTotalAmounts = function (searchParams) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "invoice/GetTotalAmounts",
                data: searchParams
            });
        };

        webService.getInvoicesMedium = function (searchParams) {
            return $http.post(baseUrl + "invoice/GetInvoicesMedium", searchParams);
        };

        webService.getInvoicesFull = function (searchParams) {
            return $http.post(baseUrl + "invoice/GetInvoicesFull", searchParams);
        };

        webService.approveInvoice = function (invoiceAction, stepNumber) {
            return $http.post(baseUrl + "invoice/Approve" + (stepNumber !== undefined ? ("?stepNumber=" + stepNumber) : ""), invoiceAction);
        };

        webService.replaceTasks = function (workFlowObject, confirmFirst) {
            return $http.post(baseUrl + "invoice/ReplaceTasksInWorkflow?confirmFirst=" + confirmFirst, workFlowObject);
        };

        webService.exportStatusReset = function (invoiceId) {
            return $http.get(baseUrl + "invoice/ExportStatusReset/" + invoiceId);
        }

        webService.updateInvoiceExportSetting = function (invoiceId, value) {
            return $http.get(baseUrl + "Invoice/UpdateInvoiceExportSetting/" + invoiceId + "?enabledExport=" + value);
        };

        webService.approveInvoices = function (invoceIds) {
            return $http.post(baseUrl + "invoice/ApproveInvoices", invoceIds);
        };

        webService.removeDuplicateFlag = function (id) {
            return $http.post(baseUrl + "invoice/RemoveDuplicateFlag/", id);
        };

        webService.checkIsDuplicate = function (obj) {
            return $http.post(baseUrl + "invoice/CheckIsDuplicate", obj);
        };

        webService.saveInvoice = function (invoice) {
            return $http({
                url: baseUrl + "invoice/Save",
                method: 'POST',
                transformRequest: function() {
                  return stringToNumber(invoice);
                },
                transformResponse: function(value) {
                    return longsToStrings(value);
                }
            });
        };

        webService.saveInvoiceCopy = function (invoice, originalId) {
            return $http.post(baseUrl + "invoice/SaveCopy?originalId=" + originalId, invoice);
        };

        webService.copyToAllTransactionRows = function (transactionRow, originalId, orderno) {
            return $http.post(baseUrl + "TransactionRow/CopyDown?invoiceId=" + originalId + (orderno ? ("&orderno=" + orderno) : ""), transactionRow);
        };
        webService.copyDown = function (transactionRowId) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "TransactionRow/CopyDown/" + transactionRowId
            });
        };
        webService.saveInvoiceCustomField = function (field) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "company/AddInvoiceCustomField/",
                data: field
            });
        };

        webService.saveInvoiceCustomFieldItem = function (field) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "company/AddInvoiceCustomFieldItem/",
                data: field
            });
        };

        webService.deleteInvoiceCustomField = function (field) {
            return queueFactory({
                method: "GET",
                url: baseUrl + "company/DeleteInvoiceCustomField/" + field.Id
            });
        };

        webService.getInvoiceData = function (id) {
            return $http({
                url:baseUrl + "invoice/GetInvoiceDataById/" + id,
                method: 'GET',
                transformResponse: function(value) {
                  return longsToStrings(value);
                }
            });
        };

        webService.getInvoiceRowsWithPaging = function (id, page, pagesize) {
            return $http({
                url: baseUrl + "invoiceRow/GetInvoiceRowsById/" + id + "?page=" + page + "&pagesize=" + pagesize,
                method: 'GET',
                transformResponse: function(value) {
                  return longsToStrings(value);
                }
            });
        };

        webService.getAccountingRowsWithPaging = function (id, page, pagesize) {
            return $http({
                url: baseUrl + "invoice/GetInvoiceTransactionRows/" + id + "?page=" + page + "&pagesize=" + pagesize,
                method: 'GET',
                transformResponse: function(value) {
                  return longsToStrings(value);
                }
              });
        };

        webService.deleteField = function (id) {
            return $http.get(baseUrl + "invoice/Deletefield/" + id);
        };

        webService.addInvoiceRow = function (invoiceRow, invoiceId) {
            return $http.post(baseUrl + "invoice/AddInvoiceRow?invoiceId=" + invoiceId, invoiceRow);
        };

        webService.addNewTransactionRow = function (dto) {
            return $http({
                url: baseUrl + "TransactionRow/AddTransactionRow", dto,
                method: 'POST',
                transformRequest: function() {
                  return stringToNumber(dto);
                }
            });
        };

        webService.updateInvoiceRow = function (invoiceRow, invoiceId) {
            return $http({
                url: baseUrl + "invoiceRow/UpdateInvoiceRow?invoiceId=" + invoiceId, invoiceRow,
                method: 'POST',
                transformRequest: function() {
                  return stringToNumber(invoiceRow);
                }
            });
        };

        webService.updateTransactionRow = function (row) {
            return $http({
                url: baseUrl + "TransactionRow/UpdateTransactionRow", row,
                method: 'POST',
                transformRequest: function() {
                  return stringToNumber(row);
                }          
              });
        };

        webService.updateTransactionRowProductItemBuyerId = function (transactionRowId, newProductItem) {
            return $http.post(baseUrl + "TransactionRow/UpdateTransactionRowProductItemBuyerId?transactionRowId=" + transactionRowId + "&newProductItem=" + (newProductItem || ''));
        };

        webService.deleteInvoiceRow = function (invoiceRow, invoiceId) {
            return $http.post(baseUrl + "invoice/DeleteInvoiceRow?invoiceId=" + invoiceId, invoiceRow);
        };
        webService.deleteTransactionRow = function (rowId) {
            return $http.post(baseUrl + "TransactionRow/DeleteTransactionRow/", rowId);
        };

        webService.resetInvoiceRows = function (invoiceRow, invoiceId) {
            return $http.post(baseUrl + "invoice/ResetInvoiceRows?invoiceId=" + invoiceId, invoiceRow);
        };

        webService.createAccountingRows = function (invoiceId) {
            return $http.post(baseUrl + "invoice/CreateAccountingRows?invoiceId=" + invoiceId);
        };

        webService.createTransactionRowsWithAT = function (invoiceId) {
            return $http.post(baseUrl + "invoice/CreateTransactionRowsWithAT?invoiceId=" + invoiceId);
        };
        
        webService.getInvoiceTransactionRowsVatRates = function (invoiceId) {
            return $http.get(baseUrl + 'Invoice/GetInvoiceTransactionRowsVatRates?invoiceId=' + invoiceId);
        };

        webService.getInvoiceTransactionRowsVatSum = function (invoiceId) {
            return $http.get(baseUrl + 'Invoice/GetInvoiceTransactionRowsVatSum?invoiceId=' + invoiceId);
        };

        webService.canMergeTransactionRows = function (invoiceId, transactionRowsIds) {
            return $http.post(baseUrl + 'Invoice/CanMergeTransactionRows?invoiceId=' + invoiceId, transactionRowsIds);
        };

        webService.mergeAccountingRows = function (rowIds) {
            return $http.post(baseUrl + "invoice/MergeAccountingRows/", rowIds);
        };

        webService.MergeAllAccountingRows = function (invoiceid) {
            return $http.get(baseUrl + "invoice/MergeAllAccountingRows/" + invoiceid);
        };

        webService.splitTransactionRow = function (splitObj) {
            return $http.post(baseUrl + "invoice/SplitTransactionRow/", splitObj);
        };

        webService.UpdateCustomField = function (customField, distributionItemId) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "invoice/UpdateCustomField?id=" + distributionItemId,
                data: customField
            });
        };

        webService.UpdateAccount = function (accountId, distributionItemId) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "invoice/UpdateAccount?accountId=" + (accountId || '') + "&id=" + distributionItemId
            });
        };

        webService.importTransactionRowsXls = function (file) {
            return $http.post(baseUrl + "invoice/ImportTransactionRowsXls/", file);
        };

        webService.UpdateSupplierId = function (supplierId, invoiceId) {
            return $http.get(baseUrl + "invoice/UpdateSupplierId?supplierId=" + supplierId + "&invoiceId=" + invoiceId);
        };

        webService.ChangeInvoiceCompany = function (companyId, invoiceId) {
            return $http.get(baseUrl + "invoice/ChangeInvoiceCompany?companyReceiverGuid=" + companyId + "&invoiceId=" + invoiceId);
        };

        webService.uploadInvoiceFile = function (file) {
            return $http.post(baseUrl + "invoice/UploadInvoiceFile", file);
        };

        webService.deleteInvoiceFile = function (file) {
            return $http.post(baseUrl + "invoice/DeleteInvoiceFile", file);
        };

        webService.setInvoiceDeleted = function (invoiceId) {
            return $http.post(baseUrl + "invoice/SetInvoiceToDeleted/", invoiceId);
        };

        webService.getDomasErrorDefinitions = function () {
            return $http.get(baseUrl + "invoice/GetDomasErrorDefinitions");
        };

        webService.sendComplaint = function (complaint) {
            return $http.post(baseUrl + "invoice/SendComplaint", complaint);
        };

        webService.getInvoiceHistory = function (invoiceId) {
            return $http.get(baseUrl + "invoice/GetInvoiceHistory?invoiceId=" + invoiceId);
        };

        webService.getInvoiceHeaderChanges = function (id) {
            return $http.get(baseUrl + "invoice/GetInvoiceHeaderChanges?id=" + id);
        };

        /// //
        // Archive webservices
        /// /
        webService.getArchiveInvoicesSmall = function (searchParams) {
            return $http.post(baseUrl + "ArchiveInvoice/GetInvoicesSmall", searchParams);
        };

        webService.getArchiveInvoiceData = function (id) {
            return $http.get(baseUrl + "ArchiveInvoice/GetInvoiceDataById/" + id);
        };

        webService.getArchiveInvoiceRowsWithPaging = function (id, page, pagesize) {
            return $http.get(baseUrl + "ArchiveInvoice/GetInvoiceRowsById/" + id + "?page=" + page + "&pagesize=" + pagesize);
        };

        webService.getArchiveAccountingRowsWithPaging = function (id, page, pagesize) {
            return $http.get(baseUrl + "ArchiveInvoice/GetInvoiceTransactionRows/" + id + "?page=" + page + "&pagesize=" + pagesize);
        };

        /// //
        // WorkflowTemplate webservices
        /// /
        webService.updateWorkflowTemplates = function (templates) {
            return $http.post(baseUrl + "WorkflowTemplate/UpdateTemplates", templates);
        };

        webService.getCompanyTemplates = function (searchParams) {
            return $http.post(baseUrl + "WorkflowTemplate/GetCompanyTemplates/", searchParams);
        };

        webService.getWorkflowById = function (Id) {
            return $http.get(baseUrl + "WorkflowTemplate/GetById/" + Id);
        };

        webService.addWorkflowTemplateItem = function (workflowTemplateItem) {
            return $http.get(baseUrl + "WorkflowTemplate/AddWorkflowTemplateItem/", workflowTemplateItem);
        };

        webService.deleteWorkflowTemplates = function (templates) {
            return $http.post(baseUrl + "WorkflowTemplate/DeleteTemplates", templates);
        };

        /// //
        // vatCode webservices
        /// /
        webService.getActiveVatCodesForUser = function (searchParams, date) {
            if (!searchParams) {
                searchParams = {
                    Restrictions: [
                        {
                            Field: 'GeneralSearch',
                            Value: '',
                            Values: undefined,
                            FieldSearchType: SearchType.NotSelected,
                        },
                    ],
                    SortItems: [
                        {
                            SortColumn: 'Code',
                            SortDirection: SortDirection.Asc,
                        },
                    ],
                    PagingOptions: {
                        Page: 1,
                        Count: 10000,
                    },
                }
            }

            return api.user.getActiveVatCodesForUser(searchParams, true, date);
        };

        webService.addVatCode = function (vatCode) {
            return $http.post(baseUrl + "vatCode/AddVatCode", vatCode);
        };

        webService.editVatCode = function (vatCode) {
            return $http.post(baseUrl + "vatCode/EditVatCode", vatCode);
        };

        webService.toggleVatCodeDefault = function (vatCode) {
            return $http.post(baseUrl + "vatCode/ToggleVatCodeDefault", vatCode);
        };

        webService.deleteVatCode = function (id) {
            return $http.delete(baseUrl + "vatCode/DeleteVatCode/" + id);
        };

        webService.uploadCSVFileToVatCodes = function (file) {
            return $http.post(baseUrl + "vatCode/UploadCSVFile", file);
        };

        /// //
        // Substitute webservices
        /// /
        webService.getCompanySubstitutes = function (id) {
            return queueFactory({ method: "GET", url: baseUrl + "substitute/GetCompanySubstitutes/" + id });
        };

        webService.getUserSubstitutes = function (id) {
            return $http.get(baseUrl + "substitute/GetUsersSubstitutes/" + id);
        };
        webService.getSubstituteSubstitute = function (id) {
            return $http.get(baseUrl + "substitute/GetSubstituteSubstitute/" + id);
        };

        webService.addOrUpdateSubstitute = function (substitute) {
            return $http.post(baseUrl + "substitute/AddOrUpdateSubstitute", substitute);
        };

        webService.deleteUserSubstitutes = function (id) {
            return $http.post(baseUrl + "substitute/Delete/" + id);
        };

        /// //
        // Chat webservices
        /// /
        webService.sendChatMessage = function (msg) {
            return $http.post(baseUrl + "chat/post", msg);
        };

        webService.getAllRecentAdminChatInfo = function () {
            return $http.get(baseUrl + "chat/GetAllRecentAdminChatInfo/");
        };

        webService.markMessagesAsRead = function (to, from) {
            return $http.get(baseUrl + "chat/MarkMessagesAsRead/?to=" + to + "&from=" + from);
        };

        webService.getAllUnread = function (to) {
            return $http.get(baseUrl + "chat/GetAllUnread/?to=" + to);
        };

        webService.getAllUnreadFrom = function (to, from) {
            return $http.get(baseUrl + "chat/GetAllUnread/?to=" + to + "&from=" + from);
        };

        webService.getAllRecentOrUnreadFrom = function (forUser) {
            return $http.get(baseUrl + "chat/GetAllRecentOrUnreadFrom/?forUser=" + forUser);
        };

        /// //
        // Account webservices
        /// /
        webService.getAccounts = function (searchParams, date) {
            if (!searchParams) {
                searchParams = {
                    Restrictions: [
                        {
                            Field: 'GeneralSearch',
                            Value: '',
                            Values: undefined,
                            FieldSearchType: SearchType.NotSelected,
                        },
                    ],
                    SortItems: [
                        {
                            SortColumn: 'Code',
                            SortDirection: SortDirection.Asc,
                        },
                    ],
                    PagingOptions: {
                        Page: 1,
                        Count: 1,
                    },
                }
            }

            return api.user.getActiveAccountsForUser(searchParams, true, date);
        };
        webService.getInvoiceAccounts = function (searchParams) {
            return $http.get(baseUrl + "invoice/GetAccounts", searchParams);
        };

        webService.addAccount = function (account) {
            return $http.post(baseUrl + "account/AddAccount", account);
        };

        webService.editAccount = function (account) {
            return $http.post(baseUrl + "account/EditAccount", account);
        };

        webService.deleteAccount = function (id) {
            return $http.delete(baseUrl + "account/DeleteAccount/" + id);
        };

        webService.getAccountById = function (id) {
            return $http.get(baseUrl + "account/GetAccountById/" + id);
        };

        webService.getAccountsByDescriptionrPart = function (description) {
            return $http.get(baseUrl + "account/GetAccountsByDescription/?description=" + description);
        };

        webService.uploadCSVFileToAccounts = function (file) {
            return $http.post(baseUrl + "account/UploadCSVFile", file);
        };

        /// //
        // Task webservices
        /// /
        webService.updateVatCode = function (item, invoiceid) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "TransactionRow/UpdateVatCode/?invoiceid=" + invoiceid,
                data: item
            });
        };

        webService.updateComment = function (rowId, comment) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "TransactionRow/UpdateComment/" + rowId,
                data: { Comment: comment }
            });
        };

        webService.updateDate = function (rowId, date) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "TransactionRow/UpdateDate/" + rowId,
                data: { Date: date }
            });
        };

        webService.uploadTransactionRowsFile = function (file, invoiceId) {
            return $http.post(baseUrl + `TransactionRow/UploadTransactionRowsFile/${invoiceId}`, file);
        };

        /// //
        // Filter>Templates webservices
        /// /
        webService.getFilterTemplates = function () {
            return $http.get(baseUrl + "FilterTemplate/GetTemplates");
        };

        webService.saveFilterTemplate = function (filterTemplate) {
            return $http.post(baseUrl + "FilterTemplate/SaveFilterTemplate", filterTemplate);
        };

        /// //
        // CustomCostObjective webservices
        /// /
        webService.getCustomCostObjectivesByName = function (namePart, includeDimensions) {
            var includeDimensionsFlag = includeDimensions === undefined ? 1 : includeDimensions;
            var encName = encodeURIComponent(namePart || '');
            return $http.get(baseUrl + "CustomCostObjective/GetByDescriptionPart?descriptionPart=" + encName + "&includeDimensions=" + !!includeDimensionsFlag);
        };
        webService.getArchiveCustomCostObjectives = function () {
            return $http.get(baseUrl + "ArchiveInvoice/GetCustomCostObjectives");
        };
        webService.getArchiveAccounts = function () {
            return $http.get(baseUrl + "ArchiveInvoice/GetAccounts");
        };

        webService.getCustomCostObjective = function (id) {
            return $http.get(baseUrl + "CustomCostObjective/GetCustomCostObjective/" + id);
        };

        webService.getDimensions = function (id, searchParams, date) {
            return api.user.getActiveCostObjectiveItemsForUser(searchParams, id, true, date)
        };

        webService.getAllCompanyCustomCostObjectives = function (date) {
            const searchParams = {
                Restrictions: [],
                SortItems: [
                    {
                        SortColumn: 'Code',
                        SortDirection: SortDirection.Asc,
                    },
                ],
                PagingOptions: {
                    Page: 1,
                    Count: 10000,
                },
            };
            return api.user.getActiveCostObjectiveItemsForUser(searchParams, 0, true, date)
        };
        webService.getAllCompanyCustomCostObjectivesWithItemCount = function (date) {
            const searchParams = {
                Restrictions: [],
                SortItems: [
                    {
                        SortColumn: 'Code',
                        SortDirection: SortDirection.Asc,
                    },
                ],
                PagingOptions: {
                    Page: 1,
                    Count: 10000,
                },
            };
            return api.user.getCostObjectivesAndItemsCountForUser(searchParams, true, date)
        };

        webService.getAllCompanyCustomCostObjectivesWithoutItems = function () {
            return $http.get(baseUrl + "CustomCostObjective/GetCompanyCustomCostObjectivesWithoutDimensions");
        };

        webService.addCustomCostObjectives = function (item) {
            return $http.post(baseUrl + "CustomCostObjective/AddCustomCostObjective", item);
        };

        webService.editCustomCostObjectives = function (item) {
            return $http.post(baseUrl + "CustomCostObjective/EditTemplate", item);
        };

        webService.deleteCustomCostObjectives = function (id) {
            return $http.delete(baseUrl + "CustomCostObjective/DeleteCustomCostObjective/" + id);
        };

        webService.exportInvoicesToCSV = function (searchParams) {
            delete $http.defaults.headers.common["X-Requested-With"];
            return $http({
                method: "POST",
                url: baseUrl + "invoice/ExportInvoicesToCSV",
                responseType: "blob",
                data: searchParams
            });
        };

        webService.exportInvoicesToXls = function (searchParams) {
            delete $http.defaults.headers.common["X-Requested-With"];
            return $http({
                method: "POST",
                url: baseUrl + "invoice/ExportInvoicesToXls",
                responseType: "blob",
                data: searchParams
            });
        };

        webService.addDimension = function (item) {
            return $http.post(baseUrl + "CustomCostObjective/AddDimension", item);
        };

        webService.editDimension = function (item) {
            return $http.post(baseUrl + "CustomCostObjective/UpdateTemplateItem", item);
        };

        webService.deleteDimension = function (id) {
            return $http.delete(baseUrl + "CustomCostObjective/DeleteDimension/" + id);
        };

        webService.uploadCSVFileToTemplateItems = function (file) {
            return $http.post(baseUrl + "CustomCostObjective/UploadCSVFile", file);
        };

        /// //
        // Auth webservices
        /// /
        webService.ssoLogin = function (credentials) {
            return $http.post(baseUrl + "Login/login", credentials);
        };

        webService.ssoLogOut = function (token) {
            return $http.post(baseUrl + "Login/Logout?token=" + token);
        };

        webService.setSessionData = function (companyGuid) {
            return queueFactory({ method: "POST", url: baseUrl + "Session/SetCompany?companyGuid=" + companyGuid });
        };

        webService.extendSession = function () {
            return $http.post(baseUrl + "Session/ExtendSession");
        };

        /// //
        // Currency webservices
        /// /
        webService.getCurrencyList = function () {
            return $http.get(baseUrl + "Currency/GetAll");
        };

        /// /
        // RelatedDimensions webservices
        /// /
        webService.getRelatedDimensionsList = function (searchParams) {
            return $http.post(baseUrl + "RelatedDimensions/GetList", searchParams);
        };

        webService.uploadCSVFileToRelatedDimensions = function (file) {
            return $http.post(baseUrl + "RelatedDimensions/UploadCSVFile", file);
        };

        webService.UpdateCustomFieldWithRelations = function (dimensionDto, accountingRowId) {
            return queueFactory({
                method: "POST",
                url: baseUrl + "invoice/UpdateCustomFieldWithRelations?rowId=" + accountingRowId,
                data: dimensionDto
            });
        };

        /// /
        // New AutoTransaction webservices
        /// /

        webService.isAutoTransactionFinished = (invoiceId) => {
            return $http.get(baseUrl + "AutoTransactions/IsAutoTransactionFinished?invoiceId=" + invoiceId);
        };

        webService.getAutoTransactionsList = function (searchParams) {
            return $http.post(baseUrl + "AutoTransactions/GetList", searchParams);
        };

        webService.toggleRuleActivity = function (atId) {
            return $http.get(baseUrl + "AutoTransactions/ToggleRuleActivity?atId=" + atId);
        };

        webService.saveAutoTransaction = function (autoTransaction) {
            return $http.post(baseUrl + "AutoTransactions/SaveAutoTransaction", autoTransaction);
        };

        webService.checkAutoTransactionNameForDuplicates = function (atName, currentAtId) {
            var encName = encodeURIComponent(atName || '');
            return $http.get(baseUrl + "AutoTransactions/CheckAutoTransactionNameForDuplicates?atName=" + encName + "&currentAtId=" + currentAtId);
        };

        webService.deleteAutoTransaction = function (atId) {
            return $http.get(baseUrl + "AutoTransactions/DeleteAutoTransaction?atId=" + atId);
        };

        webService.duplicateAutoTransaction = function (atId) {
            return $http.get(baseUrl + "AutoTransactions/DuplicateAutoTransaction?atId=" + atId);
        };

        webService.getAutoTransactionSnapshotForInvoice = function (invoiceId) {
            return $http.get(baseUrl + "AutoTransactions/GetAutoTransactionSnapshotForInvoice?invoiceId=" + invoiceId);
        };

        webService.getAutoTransactionForUiById = function (id) {
            return $http.get(baseUrl + "AutoTransactions/GetAutoTransactionForUiById?id=" + id);
        };

        webService.getAutoTransactionByNamePart = function (searchString, resultLimit) {
            var encName = encodeURIComponent(searchString || '');
            return $http.get(baseUrl + "AutoTransactions/GetByNamePart?searchParam=" + encName + "&amount=" + resultLimit);
        };

        webService.applyAutoTransactionToInvoice = function (atId, invoiceId, recreateTransactionRows) {
            return $http.get(baseUrl + "AutoTransactions/ApplyAutoTransactionToInvoice?atId=" + atId + "&invoiceId=" + invoiceId + "&recreateTransactionRows=" + recreateTransactionRows);
        };

        webService.getEmptyUiObject = function () {
            return $http.get(baseUrl + "AutoTransactions/GetEmptyUiObject");
        };

        /// /
        // PurchaseOrders webservices
        /// /
        webService.getPurchaseOrdersList = function (searchParams) {
            return $http.post(baseUrl + "PurchaseOrders/GetList", searchParams);
        };

        webService.getPurchaseOrderById = function (orderId) {
            return $http.get(baseUrl + "PurchaseOrders/GetPurchaseOrderById?id=" + orderId);
        };

        /// /
        // ProductItems webservices
        /// /

        webService.getProductItemsList = function (searchParams) {
            return $http.post(baseUrl + "ProductItem/GetList", searchParams);
        };

        webService.uploadCSVFileToProductItems = function (file) {
            return $http.post(baseUrl + "ProductItem/UploadCSVFile", file);
        };

        webService.deleteProductItem = function (itemId, searchParams) {
            return $http.post(baseUrl + "ProductItem/DeleteItem?itemId=" + itemId, searchParams);
        };

        webService.saveProductItemsList = function (item) {
            return $http.post(baseUrl + "ProductItem/SaveItem", item);
        };

        return webService;
    }
})();