import { isEmpty, filter, cloneDeep, groupBy, size } from 'lodash-es';
import { eventTrack } from '../../../src/common/analytics/gtm';
import { Role } from "../../../src/common/user/userPermissionUtil";
import { InvoiceStatus } from "../../../src/common/constants/appConstants";
import { CompanySetting } from '../../../src/common/user/userCompanySettingsUtil';
import { numericSortCodes } from '../../../src/common/utils/numericSort';
import { SearchType, SortDirection } from "../../../src/services/types/ApiTypes";
import { formatDate } from '../../../src/common/utils/formatters';
import { setCurrentCompanySettings, setCurrentCompanySettingsLoading } from "../../../src/common/company/CompanyActions";
import { CompanySettingStatus } from '../../../src/views/settings/company-settings/components/Settings/CompanySettingsListHelper';

"use strict";
angular
    .module("dstreamApp.components")
    .component("dsTransactionRows", {
        templateUrl: "app/components/transactionRows/ds-transaction-rows.html",
        controller: DsTransactionRowsController,
        bindings: {
            invoice: "<",
            invoiceId: "<",
        }
    });
/* @ngInject */
var initialTAHeigth = 0;

function autoGrow(element) {
    if (element === "" || element == null) {
        element = document.getElementById("taComment");
    }
    if (initialTAHeigth === 0) {
        initialTAHeigth = element.clientHeight;
    }
    if (element.scrollHeight > initialTAHeigth) {
        element.scrollTop = 0;
        element.style.height = initialTAHeigth + "px";
        element.style.height = (element.scrollHeight) + "px";
    }
}



function DsTransactionRowsController(
    $rootScope,
    $uibModal,
    $scope,
    $timeout,
    $filter,
    $q,
    $document,
    $routeParams,
    $analytics,
    $ngRedux,
    activeInvoiceService,
    autoTransactionsService,
    authenticationService,
    companyDataService,
    confirmationService,
    constants,
    invoiceService,
    localStorageService,
    notifyService,
    utilityService,
    webServices,
) {
    var ctrl = this;

    const midLayerMandatoryCOs = ['JBKJS_CODE', 'YEAR_CODE', 'PROGRAM_CODE', 'PROJECT_CODE', 'ECONOMIC_CODE', 'FUNCTION_CODE', 'SOURCE_CODE']
    const midLayerOptionalCOs = ['MONTH_CODE', 'ORDINALNUMBER_CODE']

    ctrl.formInitializers = constants.FormInitEvent;

    /** *** CONTROLLER PUBLIC VARIABLES DECLARATIONS *****/
    ctrl.transactionRowsRendered = 0;
    ctrl.vatCodesRendered = 0;
    ctrl.customFieldsRendered = 0;
    ctrl.transactionRowsCount = 0;
    ctrl.transactionRowsLoadingInFlight = false;
    ctrl.companyDataLoaded = false;
    ctrl.visible = true;
    ctrl.customCostObjectives = [];
    ctrl.missingCustomCostObjectives = [];
    ctrl.missingCustomCostObjectivesText = "";
    ctrl.VatCodes = [];
    ctrl.AccountList = [];
    ctrl.templateApplying = localStorageService.get("transactionRows.ApplyingTemplate") || {
        inProgress: false,
        transactionRowId: null
    };
    ctrl.invoiceAccountingRowsLoading = true;
    ctrl.customFieldBackup = {};
    ctrl.productCodeBackup = {};
    ctrl.accountBackup = {};
    ctrl.vatCodeBackup = {};
    ctrl.validateTransctRows = false;
    ctrl.invoiceId = 0;
    ctrl.isTransactionRowsCheckEnabled = true;
    ctrl.isTransactionRowsPenultimateCheckEnabled = true;
    ctrl.isVatSumDeviationWarningEnabled = false;
    ctrl.IsVatCodeManualEditingEnabled = false;
    ctrl.expandedRows = [];
    ctrl.MergeOpen = false;
    ctrl.expandAllRowsDefault = true;
    ctrl.allCheckedCheckBox = false;
    ctrl.transactionRows = [];
    ctrl.transactionRowsVatSum = 0;
    ctrl.allTransactionRowsVatRates = [];
    ctrl.paginator = {
        currentPage: 1,
        count: localStorageService.get("accountingRowsPageSize") || 10,
        total: 0
    };
    ctrl.pageToReturnAfterMerge = -1;
    ctrl.canCreateTemplate = false;
    ctrl.bypassPageChange = false;
    ctrl.loading = true;
    ctrl.template = { Description: "" };
    ctrl.combineAllAttributes = { // combine all model
        vat: "",
        dimensions: "",
        account: ""
    };
    ctrl.dateOptions = { // date options for the calendar
        showWeeks: false,
        startingDay: 1
    };
    ctrl.rowsToBeMerged = [];
    ctrl.isMergeDisabled = true;
    ctrl.pageSizes = constants.DEFAULT_PAGE_SIZES;
    ctrl.DATE_EE_FORMAT = constants.DATE_EE_FORMAT;
    ctrl.DATE_EE_FORMAT_TIME_ONLY = constants.DATE_EE_FORMAT_TIME_ONLY;
    ctrl.loadMore = false; 
    /**
     * SCOPE variables  - Should make these controller variables
     */
    $scope.transactionRowSplit = $uibModal;
    $scope.transactionRowsCombine = $uibModal;
    $scope.dimensionsTotalCount = 0;
    $scope.handleLoadMore = handleLoadMore;
    /**
     * Controller objects for POPOVERS
     */
    // single row action popover
    ctrl.copyContentPopover = {
        template: "row-confirm-template.html",
        show: function (row) {
            row.showCopyContentPopover = true;
        },
        cancel: function (row) {
            row.showCopyContentPopover = false;
        },
        copyContent: function (row) {
            // confirm and rewrite all transaction rows
            ctrl.loading = true;
            copyDown(row);
            row.showCopyContentPopover = false;
        }
    };
    ctrl.deleteRowPopover = {
        template: "row-delete-template.html",
        show: function (row) {
            row.showDeletePopover = true;
        },
        cancel: function (row) {
            row.showDeletePopover = false;
        },
        delete: function (row) {
            // confirm and rewrite all transaction rows
            ctrl.loading = true;
            deleteRow(row);
            row.showDeletePopover = false;
        }
    };
    ctrl.combineTemplatePopover = {
        template: "transactionRows-combine-popover-template.html",
        Open: false
    };
    ctrl.additionalSettingsPopover = {
        template: "transactionRows-additionalSettings-popover-template.html",
        Open: false
    };
    ctrl.autoTransactionsTemplatePopover = {
        template: "transactionRows-autotransactions-popover-template.html",
        Open: false
    };
    ctrl.metainfoTemplatePopover = {
        template: "metaInfo-template.html",
        Open: false
    };
    ctrl.createApplyAutoTransactionTemplatePopover = {
        template: "apply-autotransactiontemplate-confirm-template.html",
        Open: false,
        quickConfirm: function () {
        },
        cancel: function () {
            closeAll();
        },
        confirm: function () {
            closeAll();
            ctrl.applyTemplate();
            ctrl.template.Name = "";
        }
    };
    // TODO DEPRECATED use template from templateService instead
    ctrl.createImportXlsPopover = {
        template: "transaction-rows-import-xls-confirm-template.html",
        Open: false,
        canImportXls: function () {
            return authenticationService.isAuthorized("CanImportXLS") && ctrl.invoice.Status < 3;
        },
        quickConfirm: function () {
        },
        cancel: function () {
            closeAll();
        },
        confirm: function (isOldXMLImport) {
            $scope.isOldXMLImport = isOldXMLImport;
            closeAll();
            ctrl.openModalXlsImport();
        }
    };
    /** *** CONTROLLER PUBLIC FUNCTIONS DECLARATIONS *****/
    /**
     * Single field actions
     */
    ctrl.getDimensions = getDimensions;
    ctrl.setDimensionsTotalCount = setDimensionsTotalCount;
    ctrl.deleteField = deleteField;
    ctrl.trimAfterComma = trimAfterComma;
    /**
     * Single row actions
     */
    ctrl.editRow = editRow;
    ctrl.saveRow = saveRow;
    ctrl.expandParentRow = expandParentRow;
    ctrl.editRowProductLines = editRowProductLines;
    ctrl.cancelEditRowProductLines = cancelEditRowProductLines;
    ctrl.saveRowProductLines = saveRowProductLines;
    ctrl.doTransactionRowSplit = doTransactionRowSplit;
    ctrl.addNewTransactionRow = addNewTransactionRow;
    ctrl.updateRow = updateRow;
    ctrl.cancelEdit = cancelEdit;
    ctrl.toggleMerge = toggleMerge;
    ctrl.expandChildRow = expandChildRow;
    ctrl.getBudget = getBudget;
    /**
     * Multi-row actions
     */
    ctrl.doTransactionRowsCombine = doTransactionRowsCombine;
    ctrl.deleteSelectedRows = deleteSelectedRows;
    ctrl.mergeRows = mergeRows;
    ctrl.mergeAllRows = mergeAllRows;
    ctrl.toggleMergeAllRows = toggleMergeAllRows;
    ctrl.mergeAll = mergeAll;
    ctrl.toggleMergeSingleRow = toggleMergeSingleRow;
    ctrl.expandAllRows = expandAllRows;
    /**
     * Custom fields functions
     */
    ctrl.customFields = customFields;
    ctrl.getDimensionName = getDimensionName;
    ctrl.getCustomCostObjectiveName = getCustomCostObjectiveName;
    ctrl.setProductCodeBackup = setProductCodeBackup;
    ctrl.setAccountBackup = setAccountBackup;
    ctrl.setVatCodeBackup = setVatCodeBackup;
    ctrl.setCustomFieldBackup = setCustomFieldBackup;
    ctrl.canDeleteCustomField = canDeleteCustomField;
    ctrl.clearCustomField = clearCustomField;
    ctrl.clearAccountField = clearAccountField;
    ctrl.clearVatCodeField = clearVatCodeField;
    ctrl.updateCustomCostObjective = updateCustomCostObjective;
    ctrl.updateCustomField = updateCustomField;
    ctrl.updateCustomFieldById = updateCustomFieldById;
    ctrl.autoGrow = autoGrow;
    /**
     * Authentication checks
     */
    ctrl.canEditTransactionRow = canEditTransactionRow;
    ctrl.canUpdateAccountDistributionItem = canUpdateAccountDistributionItem;
    ctrl.canAddAutoConvertTemplate = canAddAutoConvertTemplate;
    ctrl.canUpdateAutoConvertTemplate = canUpdateAutoConvertTemplate;
    ctrl.canSaveInvoice = canSaveInvoice;
    ctrl.canAddTransactionRows = canAddTransactionRows;
    /**
     * Validation checks
     */
    ctrl.isProductItemsModulActive = isProductItemsModulActive;
    ctrl.validateTransactionRow = validateTransactionRow;
    ctrl.isCheckBoxChecked = isCheckBoxChecked;
    ctrl.allChecked = allChecked;
    /**
     * Auto transactions functions
     */
    ctrl.canUseAutomation = canUseAutomation;
    ctrl.canAddAutomationTemplate = canAddAutomationTemplate;
    ctrl.canApplyAutomationTemplate = canApplyAutomationTemplate;
    ctrl.applyTemplate = applyTemplate;
    ctrl.saveAccountDistributionTemplate = saveAccountDistributionTemplate;
    ctrl.openApplyAutoTransactionTemplate = openApplyAutoTransactionTemplate;
    ctrl.saveAutoTransactionSnapshot = saveAutoTransactionSnapshot;
    ctrl.applyAutotransactionEnabled = applyAutotransactionEnabled;
    ctrl.disableTemplateSelection = disableTemplateSelection;
    /**
     * Pagination functions
     */
    ctrl.changePageSize = changePageSize;
    /**
     * Comment functions
     */
    ctrl.UpdateComment = UpdateComment;
    ctrl.startUpdateComment = startUpdateComment;
    ctrl.addComment = addComment;
    ctrl.removeComment = removeComment;
    /**
     * CustomFields UI functions
     */
    ctrl.getProductItems = getProductItems;
    ctrl.numbersAfterComma = numbersAfterComma;
    ctrl.getTaskStatusTranslateKey = getTaskStatusTranslateKey;
    ctrl.getNameTranslateKey = getNameTranslateKey;
    ctrl.findVatCode = findVatCode;
    ctrl.findAccount = findAccount;
    ctrl.findProductCodeName = findProductCodeName;
    ctrl.toggleComponent = toggleComponent;
    ctrl.openAccountingDatepicker = openAccountingDatepicker;
    ctrl.updateProductItemBuyerId = updateProductItemBuyerId;
    ctrl.clearProductItem = clearProductItem;
    ctrl.updateVatCode = updateVatCode;
    ctrl.updateAccount = updateAccount;
    ctrl.SaveAccount = SaveAccount;
    ctrl.getAccountDistributionItemCustomCostObjectivesByNumberPart = getAccountDistributionItemCustomCostObjectivesByNumberPart;
    ctrl.getAccountByDescriptionPart = getAccountByDescriptionPart;
    ctrl.getAccountVatCodes = getAccountVatCodes;
    ctrl.isFinalConfirmation = isFinalConfirmation;

    /**
     * UI functions
     */
    ctrl.isUnPostedAmountNotificationVisible = isUnPostedAmountNotificationVisible;
    ctrl.isVatSumDeviationNotificationVisible = isVatSumDeviationNotificationVisible;
    ctrl.isAddTransactionRowVisible = isAddTransactionRowVisible;
    ctrl.isTransactionRowsLoading = isTransactionRowsLoading;
    ctrl.showCombinePopover = showCombinePopover;
    ctrl.canUseHorizontalView = canUseHorizontalView;
    ctrl.handleHorizontalViewEventTrack = handleHorizontalViewEventTrack;
    ctrl.showDimensionValueInput = showDimensionValueInput;
    ctrl.showCostObjectiveTypeInput = showCostObjectiveTypeInput;
    ctrl.toggleHistory = toggleHistory;
    ctrl.focusInput = focusInput;
    ctrl.UpdateDate = UpdateDate;
    ctrl.updateSum = updateSum;
    ctrl.recalculateVatBySum = recalculateVatBySum;
    ctrl.recalculateVatByTotal = recalculateVatByTotal;
    ctrl.recalculateVatByVatChange = recalculateVatByVatChange;
    ctrl.checkTextAreaHeight = checkTextAreaHeight;
    ctrl.setMessagePriority = setMessagePriority;
    ctrl.openModalXlsImport = openModalXlsImport;
    ctrl.$onDestroy = onDestroy;
    ctrl.getQtyPrecision = companyDataService.getQtyPrecision;
    ctrl.getPricePrecision = companyDataService.getPricePrecision;
    ctrl.processExport = processExport;
    /**
     * PARENT CONTROLLER FUNCTIONS
     */
    /**
     * TODO: Rewrite $parent scope functions into this controller
     */
    ctrl.updateItemAmountByPrice = $scope.$parent.updateItemAmountByPrice;
    ctrl.getConfirmerByNamePart = $scope.$parent.getConfirmerByNamePart;
    ctrl.isUserNextConfirmer = $scope.$parent.isUserNextConfirmer;
    ctrl.isUserNextConfirmerInRow = $scope.$parent.isUserNextConfirmerInRow;
    ctrl.setTemplate = $scope.$parent.setTemplate;
    ctrl.getUserFullNameById = $scope.$parent.getUserFullNameById;
    ctrl.canEditAccountDistributionItem = $scope.$parent.canEditAccountDistributionItem;
    ctrl.addCustomField = $scope.$parent.addCustomField;

    /**
     * CONTROLLER PRIVATE FUNCTIONS
     */

    function isUnPostedAmountNotificationVisible() {
        if (!isEmpty(ctrl.invoice)) {
            if (!isEmpty(ctrl.transactionRows)) {
                return ctrl.invoice.CanEditInvoice && ctrl.TransactionRowsTotalSumRemaining !== 0;
            }
        }
        return false
    }

    function setVatSumDeviation() {
        ctrl.VatSumDeviation = Math.abs(ctrl.invoice.Vat - ctrl.transactionRowsVatSum).toFixed(2);
    };

    function isVatSumDeviationNotificationVisible() {
        if (!isEmpty(ctrl.invoice)) {
            if (!isEmpty(ctrl.transactionRows)) {
                return ctrl.isVatSumDeviationWarningEnabled && Number(ctrl.VatSumDeviation) !== 0.00;
            }
        }
        return false
    }

    function isAddTransactionRowVisible() {
        if (!isEmpty(ctrl.invoice)) {
            if (!isEmpty(ctrl.transactionRows)) {
                return ctrl.showAddRow && ctrl.TransactionRowsTotalSumRemaining > 0 && !ctrl.invoice.areSumsNotMatching;
            }
        }
        return false
    }

    function isTransactionRowsLoading() {
        return ctrl.loading || ctrl.activeInvoiceLoading;
    }

    function canDeleteCustomField(customField) {
        return ctrl.invoice.canConfirmCurrentTaskProp &&
            ctrl.invoice.CanEditInvoice &&
            canSaveInvoice() &&
            !(customField.isMandatory || customField.IsVisible || customField.isAccountMandatory);
    }

    function canUseHorizontalView() {
        return ctrl.invoice.Status < 3 && authenticationService.isAuthorized("CanUpdateInvoice");
    }

    function handleHorizontalViewEventTrack() {
        eventTrack({
            event: 'transaction_rows',
            label: 'Open horizontal view',
        });
    }

    function showCostObjectiveTypeInput(customField) {
        return !(customField.isMandatory || (customField.IsVisible || customField.DimensionId) ||
            customField.isAccountMandatory || customField.Dimension) &&
            ctrl.invoice.Status < 3 &&
            authenticationService.isAuthorized("CanUpdateInvoice") &&
            ctrl.invoice.CanEditInvoice;
    }

    function showDimensionValueInput() {
        return ctrl.invoice.Status < 3 &&
            authenticationService.isAuthorized("CanUpdateInvoice") &&
            ctrl.invoice.CanEditInvoice;
    }

    function editRow(row, e) {
        e.preventDefault();
        row.edit = true;
        row.oldDescription = row.Description;
        row.oldVat = row.VAT;
        row.oldPrice = row.SumWithoutVat;
        row.oldTotal = row.Total;
    }

    function editRowProductLines(row, e) {
        e.preventDefault();
        row.editProductLines = true;
        row.oldItemAmount = row.ItemAmount;
        row.oldItemUnit = row.ItemUnit;
        row.oldItemPrice = row.ItemPrice;
        row.oldSellerProductId = row.SellerProductId;
    }

    function cancelEditRowProductLines(row) {
        row.editProductLines = false;
        row.ItemAmount = row.oldItemAmount;
        row.ItemUnit = row.oldItemUnit;
        row.ItemPrice = row.oldItemPrice;
        row.SellerProductId = row.oldSellerProductId;
    }

    function cancelEdit(row) {
        row.edit = false;
        row.Description = row.oldDescription;
        row.VAT = row.oldVat;
        row.SumWithoutVat = row.oldPrice;
        row.Total = row.oldTotal;
    }

    function isProductItemsModulActive() {
        return utilityService.isSystemSetting("IsProductItemsModulActive");
    }

    function findProductCodeName(row) {
        return row.BuyerProductName || '';
    }

    function getProductItems(name, row) {
        var namePart = name || "";
        if (namePart === row.BuyerProductName) {
            return webServices.getProductItems("").then(
                function (response) {
                    return response.data;
                });
        }
        return webServices.getProductItems(namePart).then(
            function (response) {
                return response.data;
            });
    }

    function updateProductItemBuyerId(item, parentRow) {
        parentRow.ProductCodeLoading = true;
        var code = "";
        if (item != null) {
            code = item.Code;
        }
        webServices.updateTransactionRowProductItemBuyerId(parentRow.Id, code).then(
            function (response) {
                if (response.data === true) {
                    parentRow.BuyerProductId = code;
                } else {
                    notifyService.error("component.transactionRow.errorSavingProductCode",
                        "controller.invoiceConfirmationController.Error",
                        true);
                }
                parentRow.ProductCodeLoading = false;
            });
    }

    function clearProductItem(parentRow) {
        if (parentRow.BuyerProductName === ctrl.productCodeBackup.BuyerProductName || !isEmpty(parentRow.BuyerProductName)) {
            return;
        }
        parentRow.ProductCodeLoading = true;
        webServices.updateTransactionRowProductItemBuyerId(parentRow.Id, null).then((r) => {
            parentRow.ProductCodeLoading = false;
        }).catch((e) => {
            parentRow.ProductCodeLoading = false;
        });
    }

    function customFields(cf) {
        if (ctrl.invoice) {
            if (ctrl.invoice.Status === InvoiceStatus.PendingExport || ctrl.invoice.Status === InvoiceStatus.Exported || ctrl.invoice.Status === InvoiceStatus.NotForExport) {
                return filter(cf,
                    function (cft) {
                        return cft.DimensionId !== undefined;
                    }).sort((a, b) => a.CustomCostObjective.OrderNo - b.CustomCostObjective.OrderNo);
            }
        }
        return cf.sort((a, b) => {
            if (!a.CustomCostObjective || !b.CustomCostObjective) return 0;
            return a.CustomCostObjective.OrderNo - b.CustomCostObjective.OrderNo;
        });
    }

    function numbersAfterComma(value) {
        var array = value.toString().split(".");
        if (array.length !== 2) {
            return 2;
        }
        var length = array[1].length;
        if (length > 2) {
            return length;
        }
        return 2;
    }

    /*
        On page change load new invoiceRows data
     */
    function saveRow(row) {
        if (validateRows(row)) {
            row.edit = false;
            row.Total = Number(row.Total);
            row.SumWithoutVat = Number(row.SumWithoutVat);
            delete row.transactionRowsForm;
            row.TransactionRowsDimensions.forEach(e => {
                delete e.childTransactionRowsForm;
                delete e.childTypeTransactionRowsForm;
            })
            return webServices.updateTransactionRow(row).then(
                function (response) {
                    if (response && response.data && response.status === 200) {
                        row.Total = response.data.Total;
                        /**
                         * TODO: Rewrite $parent scope functions into this controller
                         */
                        $scope.$parent.reloadInvoiceData($routeParams.id, undefined, ctrl.paginator.currentPage);
                        notifyService.success('views.invoice.partials.invoiceRows.Saving_successful', 'views.invoice.partials.invoiceRows.Success', true);
                    }
                    return response.data;
                }).catch(
                    function (data) {
                        console.log("Request rejected due to incorrect data:", data);
                    });
        }
    }

    function saveRowProductLines(row) {
        row.editProductLines = false;
        row.ItemAmount = row.ItemAmount;
        row.ItemPrice = row.ItemPrice;
        delete row.transactionRowsForm;
        row.TransactionRowsDimensions.forEach(e => {
            delete e.childTransactionRowsForm;
            delete e.childTypeTransactionRowsForm;
        })
        return webServices.updateTransactionRow(row).then(function (response) {
            if (response.data && response.status === 200) {
                row.Total = response.data.Total;
                /**
                 * TODO: Rewrite $parent scope functions into this controller
                 */
                $scope.$parent.reloadInvoiceData($routeParams.id, undefined, ctrl.paginator.currentPage);
                notifyService.success("views.invoice.partials.invoiceRows.Saving_successful", "views.invoice.partials.invoiceRows.Success", true);
            }
            return response.data;
        }, function (data) {
            console.log(data);
        });
    }

    function deleteSelectedRows() {
        if (ctrl.rowsToBeMerged.length) {
            ctrl.loading = true;
            deleteRows(ctrl.rowsToBeMerged);
            $document.find("#accounting_rows_checkbox_all").prop("checked", false);
            $document.find(".accounting_rows_checkbox").prop("checked", false);
        }
    }

    function updateInvoice(invoiceId) {
        return activeInvoiceService.activeInvoice(invoiceId).then(
            function (response) {
                ctrl.invoice = response;
                ctrl.invoiceId = $routeParams.id;
                ctrl.paginator.total = ctrl.invoice.InvoiceAccountingRowsTotal;
                return ctrl.invoice;
            }
        ).then(
            function () {
                if (!!transactionRowsLoadEvent && !!ctrl.invoice && _.isEmpty(ctrl.transactionRows) && !ctrl.transactionRowsLoadingInFlight) {
                    reloadTransactionRows(1);
                } else {
                    ctrl.loading = false
                }
            }
        );
    }

    function deleteRows(rows) {
        if (ctrl.invoice) {
            webServices.deleteTransactionRow(rows).then(function () {
                /**
                 * TODO: Rewrite $parent scope functions into this controller
                 */
                // $scope.$parent.reloadInvoiceData($routeParams.id);
                updateInvoice($routeParams.id);
                if (ctrl.transactionRows.length === rows.length && ctrl.paginator.currentPage > 1) {
                    ctrl.paginator.currentPage--;
                } else {
                    reloadTransactionRows(ctrl.paginator.currentPage);
                }
                for (var i = 0; i < rows.length; i++) {
                    var index = ctrl.rowsToBeMerged.indexOf(rows[i]);
                    if (index >= 0) {
                        ctrl.rowsToBeMerged.slice(index, 1);
                    }
                }
                ctrl.allCheckedCheckBox = ctrl.allChecked();
                ctrl.isMergeDisabled = true;
                ctrl.bypassPageChange = true;
            }).catch(function () {
                ctrl.loading = false;
                notifyService.error("controller.invoiceConfirmationController.Error", "controller.invoiceConfirmationController.Error", true);
            });
        }
    }

    function deleteRow(row) {
        webServices.deleteTransactionRow([row.Id]).then(function () {
            /**
             * TODO: Rewrite $parent scope functions into this controller
             */
            // $scope.$parent.reloadInvoiceData($routeParams.id);
            updateInvoice($routeParams.id);
            if (ctrl.transactionRows.length === 1 && ctrl.paginator.currentPage > 1) {
                ctrl.paginator.currentPage--;
            } else {
                reloadTransactionRows(ctrl.paginator.currentPage);
            }
        }).catch(function () {
            ctrl.loading = false;
            notifyService.error("controller.invoiceConfirmationController.Error", "controller.invoiceConfirmationController.Error", true);
        });
    }

    $scope.$watch(function () {
        return ctrl.loading;
    }, function (newVal, oldVal) {
    });

    function closeAll() {
        ctrl.createApplyAutoTransactionTemplatePopover.Open = false;
        ctrl.createImportXlsPopover.Open = false;
        ctrl.additionalSettingsPopover.Open = false;
        ctrl.combineTemplatePopover.Open = false;
        ctrl.autoTransactionsTemplatePopover.Open = false;
    }

    function trimAfterComma (value, symbolsCap, isPrice) {
        if (!value || !symbolsCap) return '0';
        var val = value.replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1');
        var array = val.toString().split(".");
        var len = array.length;
        if(len == 1) return isPrice ? array[0] + '.00' : array[0];
        if(len >= 2)
        {
          return array[0] + '.' + array[1].substring(0, symbolsCap);
        }
    };

    function getTaskStatusTranslateKey(action) {
        switch (action) {
            case "I":
                return "component.transactionRows.created";
            case "U":
                return "component.transactionRows.changedhistory";
            case "M":
                return "component.transactionRows.mergedRow";
            case "D":
                return "component.transactionRows.deleted";
        }
    }

    function getNameTranslateKey(name) {
        switch (name) {
            case "Amount":
                return "component.invoiceRows.quantity";
            case "SumWithoutVat":
                return "component.transactionRows.sum";
            case "Total":
                return "component.transactionRows.withVAT";
            case "Vat":
                return "component.transactionRows.VatSum";
            case "Description":
                return "component.transactionRows.description";
            case "VatCode":
                return "component.transactionRows.VAT";
            case "Comment":
                return "component.transactionRows.comment";
            default:
                return name;
        }
    }

    function getCustomCostObjectiveName(costObjective) {
        if (!costObjective) {
            return null;
        }
        return costObjective.Description;
    }

    function getDimensionName(dimension) {
        if (!dimension) {
            return null;
        }
        return dimension.Code + " - " + dimension.Description;
    }

    function findVatCode(vatcode) {
        return !vatcode ? "" : vatcode.Code + " - " + vatcode.Description;
    }

    function findAccount(row) {
        return !row.Account ? "" : row.Account.Code + " - " + row.Account.Description;
    }

    function doTransactionRowSplit(row) {
        var modalInstance = $scope.transactionRowSplit.open({
            templateUrl: "app/components/transactionRows/ds-transaction-row-split.html",
            controller: "DsTransactionRowSplitController",
            windowClass: "transactions-split-modal draggable",
            backdrop: "static",
            resolve: {
                param: function () {
                    return { "row": row, "invoice": ctrl.invoice };
                }
            }
        });
        modalInstance.result.then(function (result) {
            if (result) {
                splitAccountDistributionItems(result);
            }
        });
    }

    /* TODO: add doTransactionRowsCombine */
    function doTransactionRowsCombine() {
        ctrl.MergeOpen = false;
        var modalInstance = $scope.transactionRowsCombine.open({
            templateUrl: "app/components/transactionRowsCombine/ds-transaction-rows-combine.tpl.html",
            controller: "DsTransactionRowsCombineController as ctrl",
            windowClass: "transaction-rows-combine-modal draggable",
            backdrop: "static",
            resolve: {
                param: function () {
                    return {
                        rows: ctrl.rowsToBeMerged,
                        invoiceId: $routeParams.id
                    };
                }
            }
        });
        modalInstance.rendered.then(function () {
            var modal = angular.element(".modal__body");
            if (modal[0].scrollHeight > modal.height()) {
                modal.addClass("custom-scrollbar");
            }
        });
        modalInstance.result.then(function (result) {
            if (result) {
                combineAccountDistributionItems(result.invoiceId, result.apiObject);
            }
        });
    }

    function splitAccountDistributionItems(obj) {
        var apiObject = {
            "AccountDistributionItem": obj.transactionRow,
            "SplitBy": obj.splitBy,
            "SplitRows": obj.splitRows
        };
        apiObject.AccountDistributionItem.transactionRowsForm = null;
        apiObject.AccountDistributionItem.TransactionRowsDimensions = angular.forEach(_.filter(apiObject.AccountDistributionItem.TransactionRowsDimensions, function (item) {
            return item.Id > 0;
        }), function (dimension) {
            dimension.childTransactionRowsForm = null;
            dimension.childTypeTransactionRowsForm = null;
            dimension.Dimension = null;
            dimension.CustomCostObjective = null;
            return dimension;
        });
        webServices.splitTransactionRow(apiObject).then(function (response) {
            if (response.data) {
                updateInvoice($routeParams.id).then(
                    function () {
                        return reloadTransactionRows(ctrl.paginator.currentPage);
                    }
                );
            } else {
                notifyService.info("component.transactionRow.split.errorDuringSplit", "controller.invoiceConfirmationController.Error", true);
            }
        }, function (data) {
            console.log(data);
        });
    }

    function combineAccountDistributionItems(invoiceId, apiObj) {
        var apiObject = {
            "InformationName": apiObj.informationName,
            "ContentCount": apiObj.contentCount,
            "RowsCount": apiObj.rowsCount
        };
        ctrl.MergeOpen = false;
        webServices.mergeAccountingRowsByTransactionRowExtension(invoiceId, apiObject)
            .then(function (response) {
                if (response) {
                    reloadTransactionRows(ctrl.paginator.currentPage);
                    $scope.$parent.reloadInvoiceData(invoiceId);
                } else {
                    notifyService.error("component.transactionRow.split.errorDuringSplit", "controller.invoiceConfirmationController.Error", true);
                }
            }, function (error) {
                if (error) console.log("mergeFail:", error);
            });
    }

    function copyDown(transactionRow) {
        ctrl.loading = true;
        webServices.copyDown(transactionRow.Id).then(function () {
            reloadTransactionRows(ctrl.paginator.currentPage);
        })
            .catch(function () {
                ctrl.loading = false;
                notifyService.error("controller.invoiceConfirmationController.Error_approving_transaction", "controller.invoiceConfirmationController.Error", true);
            });
    }

    function canUpdateAccountDistributionItem() {
        return authenticationService.isAuthorized("CanUpdateAccountDistributionItem");
    }

    function canEditTransactionRow() {
        /**
         * TODO: properly check for this param
         */
        return ctrl.invoice.canConfirmCurrentTaskProp;
    }

    function canUseAutomation() {
        return authenticationService.isAuthorized(Role.CanUseAutomation);
    }

    function canAddAutomationTemplate() {
        return canUseAutomation() &&
            !!ctrl.invoice;
    }

    function isFinalConfirmation() {
        return confirmationService.isFinalConfirmation(ctrl.invoice);
    }

    function canApplyAutomationTemplate() {
        return canUseAutomation() &&
            !!ctrl.invoice &&
            [InvoiceStatus.New, InvoiceStatus.InApproval].includes(ctrl.invoice.Status);
    }

    function canAddAutoConvertTemplate() {
        return (authenticationService.isAuthorized("CanAddAutoConvertTemplate") &&
            !!ctrl.invoice && ctrl.invoice.Status !== 5);
    }

    function canUpdateAutoConvertTemplate() {
        return (authenticationService.isAuthorized("CanUpdateAutoConvertTemplate") && ctrl.invoice);
    }

    /** ****************** SIMPLE TOGGLE FUNCTIONS ********************/

    function toggleComponent() {
        ctrl.visible = !ctrl.visible;
    }

    function openAccountingDatepicker(row) {
        row.accountingDateOpen = true;
    }

    function addNewTransactionRow() {
        if (ctrl.TransactionRowsTotalSumRemaining !== 0) {
            ctrl.loading = true;
            var newPrice = Number(Math.round((ctrl.TransactionRowsTotalSumRemaining) + "e2") + "e-2");
            var newTotal = Number(Math.round((ctrl.TransactionRowsTotalSumRemaining) + "e2") + "e-2");
            var newRow = {
                Id: -1,
                IsNew: true,
                SumWithoutVat: newPrice,
                Total: newTotal,
                InvoiceId: $routeParams.id,
                Description: $filter("translate")("component.transactionRows.newRowDescription")
            };
            webServices.addNewTransactionRow(newRow).then(function (response) {
                if (response.data) {
                    var pages = ctrl.paginator.total / ctrl.paginator.count;
                    ctrl.paginator.currentPage = Math.ceil(pages) + (pages === parseInt(pages, 10) ? 1 : 0); // set page to last page
                    var scrollFunction = function () {
                        var checkExist = setInterval(function () {
                            var rowId = $("#row-" + response.data.Id);
                            if (rowId.length) {
                                rowId[0].scrollIntoView();
                                clearInterval(checkExist);
                            }
                        }, 100);
                    };
                    getTransactionRows($routeParams.id, scrollFunction);
                    updateInvoice($routeParams.id);
                }
            }).catch(function () {
                ctrl.loading = false;
            });
        }
    }

    function saveAccountDistributionTemplate() {
        if (ctrl.invoice.InvoiceAccountingRowsTotal > 100) {
            ctrl.canCreateTemplate = true;
        } else {
            ctrl.autoTransactionsTemplatePopover.Open = false;
            /**
             * TODO: Rewrite $parent scope functions into this controller
             */
            $scope.$parent.saveAccountDistributionTemplate();
        }
    }

    function clearCustomField(customFieldItem, row) {
        if (
            !!customFieldItem &&
            !!customFieldItem.Dimension &&
            customFieldItem.DimensionId === ctrl.customFieldBackup.DimensionId &&
            customFieldItem.Dimension.Id === customFieldItem.DimensionId
        ) {
            return;
        }
        row.historyVisible = false;
        row.history = {};
        if (!customFieldItem || (customFieldItem.DimensionId !== ctrl.customFieldBackup.DimensionId && !customFieldItem.DimensionId)) {
            customFieldItem.Loading = true;
            customFieldItem.DimensionId = "";
            customFieldItem.Dimension = null;
            var customFieldToSave = {
                CustomCostObjective: { ...customFieldItem.CustomCostObjective }
            };
            if (customFieldToSave.CustomCostObjective) {
                customFieldToSave.CustomCostObjectiveId = customFieldToSave.CustomCostObjective.Id;
                customFieldToSave.CustomCostObjective = null;
                customFieldToSave.childTransactionRowsForm = null;
                customFieldToSave.childTypeTransactionRowsForm = null;
            }
            webServices.UpdateCustomField(customFieldToSave, row.Id).then(function (response) {
                customFieldItem.Id = response.data.Id;
                customFieldItem.Loading = false;
            }).catch(function () {
                customFieldItem.Loading = false;
            });
        }
    }

    function getAccountVatCodes(numberPart, item, vatrate) {
        numberPart = numberPart || "";
        let vatCodes = [];
        if (!numberPart || numberPart === "" || _.find(ctrl.VatCodes, function (code) {
            return findVatCode(code) === numberPart;
        })) {
            vatCodes = ctrl.VatCodes;
        } else {
            vatCodes = _.filter(ctrl.VatCodes, function (c) {
                return c.Code.toLowerCase().indexOf(numberPart.toLowerCase()) > -1 ||
                    c.Description.toLowerCase().indexOf(numberPart.toLowerCase()) > -1;
            });
        }
        return vatCodes.sort(numericSortCodes);
    }

    function getAccountByDescriptionPart(descriptionPart, item) {
        if (ctrl.debounce) {
            $timeout.cancel(ctrl.debounce);
        }
        ctrl.debounce = $timeout(function () {
            descriptionPart = (item.Account && (`${item.Account.Code} - ${item.Account.Description}`) === descriptionPart) ? "" : descriptionPart || "";
            const searchParams = {
                Restrictions: [
                    {
                        Field: 'GeneralSearch',
                        Value: descriptionPart,
                        Values: undefined,
                        FieldSearchType: SearchType.NotSelected,
                    },
                ],
                SortItems: [
                    {
                        SortColumn: 'Code',
                        SortDirection: SortDirection.Asc,
                    },
                ],
                PagingOptions: {
                    Page: 1,
                    Count: 25,
                },
            };

            return webServices.getAccounts(searchParams, ctrl.invoice.AccountingDate).then((response) => {
                return response.data.Items.map((acc) => ({ ...acc, FullName: acc.Code + " - " + acc.Description })).sort(numericSortCodes);
            });
        }, 400);
        return ctrl.debounce;
    }

    function getAccountDistributionItemCustomCostObjectivesByNumberPart(namePart, row) {
        if (ctrl.debounce) {
            $timeout.cancel(ctrl.debounce);
        }
        ctrl.debounce = $timeout(function () {
            namePart = namePart || "";
            var exactMatch = (namePart && row && row.TransactionRowsDimensions) ? _.find(row.TransactionRowsDimensions, function (cf) {
                return cf.CustomCostObjective ? cf.CustomCostObjective.Description === namePart : false;
            }) : null;
            if (!!exactMatch) {
                namePart = "";
            }
            return webServices.getCustomCostObjectivesByName(namePart, false).then(
                function (response) {
                    if (response.status === 200) {
                        var unusedCustomCostObjectives = _.filter(response.data, function (customCostObjective) {
                            return !(_.find(row.TransactionRowsDimensions, function (customField) {
                                return customCostObjective.Id === customField.CustomCostObjectiveId;
                            }));
                        });
                        if (unusedCustomCostObjectives && unusedCustomCostObjectives.length > 20) {
                            unusedCustomCostObjectives = unusedCustomCostObjectives.slice(0, 20);
                        }
                        var sorted = _.sortBy(unusedCustomCostObjectives, function (item) {
                            return item.Name;
                        });
                        return sorted;
                    }
                }
            );
        }, 400);
        return ctrl.debounce;
    }

    function setDimensionsTotalCount (count) {
        $scope.dimensionsTotalCount = count;
    }

    function handleLoadMore (scope, $event) {
        ctrl.loadMore = true;
        scope.$emit('loadMore', scope.query);
        $event.stopPropagation();
    }

    function getDimensions(customField, name) {
        var namePart = name || "";
        var dimensions = [];
        var itemWithFullName = {};
        var count = ctrl.loadMore && namePart.length > 2 && $scope.dimensionsTotalCount ? $scope.dimensionsTotalCount : 25;
        var searchParams = {
            SortItems: [
                {
                    SortColumn: "Code",
                    SortDirection: 0
                }
            ],
            PagingOptions: {
                Count: count,
                Page: 0
            },
            Restrictions: [
                {
                    Field: "GeneralSearch",
                    Value: namePart,
                    FieldSearchType: 0
                }
            ],
        };
        if (ctrl.debounce) {
            $timeout.cancel(ctrl.debounce);
        }
        ctrl.debounce = $timeout(function () {
            if (name === getDimensionName(customField.Dimension)) {
                searchParams.Restrictions[0].Value = "";
                searchParams.PagingOptions.Count = 25;
            }
            const accountingDate = ctrl.invoice.AccountingDate ? ctrl.invoice.AccountingDate : undefined;
            dimensions = webServices.getDimensions(customField.CustomCostObjectiveId, searchParams, accountingDate).then(
                function (response) {
                    itemWithFullName = response.data.Items.map(function (item) {
                        item.FullName = getDimensionName(item);
                        return item;
                    });
                    if (searchParams.Restrictions[0].Value.length > 2 && response.data.Take < response.data.TotalCount) {
                        setDimensionsTotalCount(response.data.TotalCount);
                    } else {
                        setDimensionsTotalCount(0);
                        ctrl.loadMore = false;
                    }
                    return itemWithFullName;
                }
            );
            return dimensions;
        }, 400);
        return ctrl.debounce;
    }

    function deleteField(accountDistItem, customField) {
        for (var i = 0; i < accountDistItem.TransactionRowsDimensions.length; i++) {
            if (accountDistItem.TransactionRowsDimensions[i] === customField) {
                if (accountDistItem.TransactionRowsDimensions[i].Id) {
                    webServices.deleteField(accountDistItem.TransactionRowsDimensions[i].Id);
                }
                accountDistItem.TransactionRowsDimensions.splice(i, 1);
                break;
            }
        }
    }

    function setProductCodeBackup(productCode) {
        ctrl.productCodeBackup = cloneDeep(productCode);
    }
    function setAccountBackup(account) {
        ctrl.accountBackup = cloneDeep(account);
    }
    function setVatCodeBackup(vatCode) {
        ctrl.vatCodeBackup = cloneDeep(vatCode);
    }
    function setCustomFieldBackup(customField) {
        ctrl.customFieldBackup = cloneDeep(customField);
    }

    function updateCustomCostObjective(selectedItem, customField, row) {
        if (!_.isEmpty(selectedItem)) {
            customField.CustomCostObjective = ctrl.customCostObjectives.find(ctf => ctf.Id === selectedItem.Id);
            customField.CustomCostObjectiveId = selectedItem.Id;
            if (selectedItem.Id !== ctrl.customFieldBackup.CustomCostObjectiveId) {
                customField.Dimension = null;
            }
            customField.DimensionId = "";
            ctrl.customFieldBackup = null;
        } else {
            notifyService.error("controller.invoiceConfirmationController.Incorrect_field_mask",
                "controller.invoiceConfirmationController.Error",
                true);
        }
    }

    function changePageSize(size, e) {
        e.preventDefault();
        ctrl.invoiceAccountingRowsLoading = true;
        ctrl.loading = true;
        ctrl.paginator.count = size;
        ctrl.rowsToBeMerged = [];
        localStorageService.set("accountingRowsPageSize", ctrl.paginator.count);
        reloadTransactionRows(1);
        $analytics.eventTrack('Transaction rows', {
            category: 'Invoice Details',
            label: size + ' per page',
        });
    }

    function updateCustomField(selectedItem, customFieldItem, row) {
        if (customFieldItem) {
            if (selectedItem) {
                customFieldItem.Loading = true;
                customFieldItem.CustomCostObjectiveId = customFieldItem.CustomCostObjective.Id;
                customFieldItem.DimensionId = selectedItem.Id;
                customFieldItem.Dimension = selectedItem;
                customFieldItem.childTransactionRowsForm.customFieldValue.$setValidity("reg", true);
                webServices.UpdateCustomFieldWithRelations(customFieldItem, row.Id, $rootScope.companyData.CompanyId).then(
                    function (response) {
                        if (response.data && response.data.length) {
                            if (response.data.length > 1) {
                                ctrl.invoiceAccountingRowsLoading = true;
                            }
                            var visibleRelated = false;
                            response.data.forEach(function (relatedDimension) {
                                for (var i = 0; i < row.TransactionRowsDimensions.length; i++) {
                                    if (relatedDimension.CustomCostObjectiveId === row.TransactionRowsDimensions[i].CustomCostObjectiveId) {
                                        visibleRelated = true;
                                        if(!relatedDimension.CustomCostObjective) {
                                            relatedDimension.CustomCostObjective = customFieldItem.CustomCostObjective;
                                        }
                                        row.TransactionRowsDimensions[i] = { ...relatedDimension };
                                        break;
                                    }
                                }
                                if (!visibleRelated) {
                                    row.TransactionRowsDimensions.push(relatedDimension);
                                }
                                visibleRelated = false;
                            });
                        }
                        ctrl.invoiceAccountingRowsLoading = false;
                        reloadTransactionRowsToUpdateRelatedDimensions(row);
                        canMergeRows();
                        customFieldItem.Loading = false;
                    }).catch(function () {
                        customFieldItem.Loading = false;
                        ctrl.invoiceAccountingRowsLoading = false;
                    });
            } else {
                customFieldItem.DimensionId = "";
                notifyService.error("controller.invoiceConfirmationController.Incorrect_field_mask",
                    "controller.invoiceConfirmationController.Error",
                    true);
            }
        }
    }

    function updateCustomFieldById(data, customFieldItem, row) {
        var customCostObjective = customFieldItem.CustomCostObjective;
        var foundDimension = _.find(customCostObjective.Dimensions,
            function (cft) {
                return cft.Id === data;
            });
        if (foundDimension) {
            customFieldItem.Loading = true;
            customFieldItem.CustomCostObjectiveId = customCostObjective.Id;
            customFieldItem.DimensionId = foundDimension.Id;
            customFieldItem.Dimension = foundDimension;
            webServices.UpdateCustomField(customFieldItem, row.Id).then(function (response) {
                customFieldItem.Id = response.data.Id;
                customFieldItem.Loading = false;
            }).catch(function () {
                customFieldItem.Loading = false;
            });
        } else {
            notifyService.error("controller.invoiceConfirmationController.Incorrect_field_mask",
                "controller.invoiceConfirmationController.Error",
                true);
        }
    }

    function openApplyAutoTransactionTemplate() {
        $uibModal.open({
            templateUrl: "app/components/autoTransactions/ds-auto-transaction-apply.tpl.html",
            controller: "DsAutoTransactionApplyController",
            controllerAs: "$ctrl",
            windowClass: "auto-transaction-apply draggable",
            backdrop: "static",
            resolve: {}
        }).result.then(
            function (result) {
                if (result.template && result.template.Id) {
                    ctrl.loading = true;
                    webServices.applyAutoTransactionToInvoice(result.template.Id, ctrl.invoice.Id, result.template.RecreateTransactionRows).then(function (response) {
                        $scope.$parent.reloadInvoiceData($routeParams.id);
                        notifyService.success("views.invoice.partials.invoiceRows.Saving_successful", "views.invoice.partials.invoiceRows.Success", true);
                    }, function (error) {
                        ctrl.loading = false;
                        notifyService.error("controller.invoiceConfirmationController.Error", "controller.invoiceConfirmationController.Error", true);
                        console.log("ERROR:: applyAutoTransactionToInvoice BackEnd result", error);
                    });
                }
            },
            function (reason) {
                console.log("AutoTransactionApply modal dismissed by user:", reason);
            }
        );
    }

    function applyTemplate() {
        ctrl.loading = true;
        ctrl.autoTransactionsTemplatePopover.Open = false;
        /**
         * TODO: Rewrite $parent scope functions into this controller
         */
        $scope.$parent.applyTemplate();
        $scope.$parent.selectedTemplate = null;
        canMergeRows();
    }

    function saveAutoTransactionSnapshot() {
        autoTransactionsService.saveAutoTransactionSnapshot(ctrl.invoice.Id, ctrl.transactionRows, ctrl.TransactionRowsTotalSumRemaining);
    }

    function clearVatCodeField(row) {
        // I want falsy values to be equal (null vs undefined)
        if (row.VatCode == ctrl.vatCodeBackup.VatCode || !!row.VatCode) {
            return;
        }
        row.historyVisible = false;
        row.history = {};
        updateVatCode(null, row);
    }

    function updateVatCode(vatCode, parentRow) {
        const recalculationNeeded = vatCode && parentRow.VatCode && parentRow.VatCode.VatRate !== vatCode.VatRate;
        const originalVat = parentRow.VAT;

        parentRow.VatCode = vatCode;
        parentRow.VatCodeId = vatCode ? vatCode.Id : null;
        parentRow.VatLoading = true;
        parentRow.showVatValidation = false;

        if (recalculationNeeded) {
            // if vat rates are same - no recalculation needed.
            // workaround for situations where we imported not 100% correct data and we could face problem with "one-cent-difference" later.
            var total = parentRow.Total;
            var vatPercentage = vatCode.VatRate;
            var newSum = total * 100 / (vatPercentage + 100);
            var newVat = total - newSum;
            parentRow.SumWithoutVat = Number(Math.round(newSum + "e2") + "e-2");
            parentRow.VAT = Number(Math.round(newVat + "e2") + "e-2");
        }

        var parentRowCopy = {
            Id: parentRow.Id,
            VatCodeId: vatCode ? vatCode.Id : null,
            SumWithoutVat: parentRow.SumWithoutVat,
            VAT: parentRow.VAT
        };

        if (ctrl.debounceUpdateVatCode) {
            $timeout.cancel(ctrl.debounceUpdateVatCode);
        }
        ctrl.debounceUpdateVatCode = $timeout(() => {
            webServices.updateVatCode(parentRowCopy, ctrl.invoice.Id).then(function (result) {
                var res = result.data;
                parentRow.SumWithoutVat = res.SumWithoutVat;
                parentRow.VAT = res.VAT;
                parentRow.VatRate = res.VatRate;
                parentRow.VatLoading = false;
                ctrl.invoice.invoiceLoading = false;
                canMergeRows();

                if (recalculationNeeded && originalVat !== parentRow.VAT) {
                    webServices.getInvoiceTransactionRowsVatSum(ctrl.invoice.Id).then(function (result) {
                        if(result.data)
                        {
                            ctrl.transactionRowsVatSum = result.data;
                        }
                    });
                }

                reloadTransactionRowsToUpdateRelatedDimensions(parentRow);
            });
        }, 100);
    }

    function addCustomDimensions(accountItem, rowItem, customCostObjectives) {
        // TODO @tonister: REMOVE THIS HACK AFTER BackEnd HAS FIXED THE "GET ACCOUNTING ROWS WITH PAGING" FUNCTION
        // HACK is the _.find() function in the filter function
        rowItem.TransactionRowsDimensions = filter(rowItem.TransactionRowsDimensions, function (field) {
            return !!_.find(customCostObjectives, function (cft) {
                return cft.Id === field.CustomCostObjectiveId;
            }) && (!!field.DimensionId || field.isMandatory || field.IsVisible);
        });
        if (accountItem) {
            for (var i = 0; i < accountItem.AdditionalFields.length; i++) {
                var customCostObjective = (function (iterator) {
                    return _.find(customCostObjectives, function (r) {
                        return r.Id === accountItem.AdditionalFields[iterator].CustomCostObjectiveId;
                    });
                })(i);
                if (customCostObjective) {
                    if (!_.some(rowItem.TransactionRowsDimensions, function (customField) {
                        // if customField already is in the transactionRow, then add up-to-date Dimensions also to this
                        if (customField.CustomCostObjective.Id === customCostObjective.Id) {
                            customField.CustomCostObjective.DimensionsCount = customCostObjective.DimensionsCount;
                        }
                        return customField.CustomCostObjective && customField.CustomCostObjective.Id === customCostObjective.Id;
                    })) {
                        rowItem.TransactionRowsDimensions.push({
                            CustomCostObjective: customCostObjective,
                            CustomCostObjectiveId: customCostObjective.Id,
                            isAccountMandatory: true
                        });
                    } else {
                        (function (ft) {
                            return (_.find(rowItem.TransactionRowsDimensions, function (item) {
                                return item.CustomCostObjective && item.CustomCostObjective.Id === ft.Id;
                            }).isAccountMandatory = true);
                        })(customCostObjective);
                    }
                }
            }
        }
    }

    function setMandatoryFields(item, customFields) {
        angular.forEach(item.TransactionRowsDimensions, function (cf) {
            var foundCF = _.find(customFields, function (item) {
                return item.Id === cf.CustomCostObjective.Id && item.IsMandatory;
            });
            if (foundCF) {
                cf.isAccountMandatory = true;
            }
        });
    }

    function clearAccountField(row) {
        if ((isEmpty(row.Account) && (row.transactionRowsForm && isEmpty(row.transactionRowsForm.accountDescription.$viewValue)))
            || (!isEmpty(row.Account) && (row.transactionRowsForm && !isEmpty(row.transactionRowsForm.accountDescription.$viewValue)))) {
            return;
        }
        row.historyVisible = false;
        row.history = {};
        row.Account = null;
        if (ctrl.debounceUpdateAccount) {
            $timeout.cancel(ctrl.debounceUpdateAccount);
        }
        ctrl.debounceUpdateAccount = $timeout(() => {
            webServices.UpdateAccount(null, row.Id).then(function (response) {
                ctrl.accountBackup = {};
                row = {
                    ...response.data,
                    AccountLoading: false,
                };
            }).catch(function () {
                row.AccountLoading = false;
            });
        }, 100);
    }

    function restoreAccountFieldValue() {
        return findAccount(ctrl.accountBackup);
    }
    ctrl.restoreAccountFieldValue = restoreAccountFieldValue;

    function updateAccount(account, row) {
        if (account) {
            row.AccountLoading = true;
            row.showAccountValidation = false;
            row.AccountDescription = account.Description;
            row.Account = account;
        }

        companyDataService.getCustomCostObjectives(true).then(
            function (response) {
                addCustomDimensions(account, row, response);
                sortCustomFields(row);
                ctrl.SaveAccount(account, row);
            }
        )
    }

    function SaveAccount(account, row) {
        if (ctrl.debounceUpdateAccount) {
            $timeout.cancel(ctrl.debounceUpdateAccount);
        }
        ctrl.debounceUpdateAccount = $timeout(() => {
            webServices.UpdateAccount(account ? account.Id : null, row.Id).then(function (response) {
                row.AccountLoading = false;
                row.Account = account;
                row.AccountId = account ? account.Id : null;
                row.AccountDescription = account.Description;
                reloadTransactionRowsToUpdateRelatedDimensions(row);
            }).catch(function () {
                row.AccountLoading = false;
            });
        }, 100);
    }

    /*
        Check if individual checkbox is checked
     */
    function isCheckBoxChecked(id) {
        return ctrl.rowsToBeMerged.indexOf(id) !== -1;
    }

    /*
     Merge and unmerge all rows checkbox
     */
    function toggleMerge(id) {
        var index = ctrl.rowsToBeMerged.indexOf(id);
        if (index === -1) {
            // checked
            ctrl.rowsToBeMerged.push(id);
            ctrl.allCheckedCheckBox = ctrl.allChecked();
        } else {
            // unchecked
            ctrl.rowsToBeMerged.splice(index, 1);
            ctrl.allCheckedCheckBox = ctrl.allChecked();
        }
        // if there is no or only one row to merge then disabled
        // ctrl.isMergeDisabled = ctrl.rowsToBeMerged.length === 1;
        // if we merge rows from several pages, try to remember first page with selection.
        if (ctrl.rowsToBeMerged.length === 0) {
            // reset
            ctrl.pageToReturnAfterMerge = -1;
        } else if (ctrl.rowsToBeMerged.length === 1) {
            // remember only first selection
            ctrl.pageToReturnAfterMerge = ctrl.paginator.currentPage;
        }
    }

    /*
        Check if all rows are selected
     */
    function allChecked() {
        var checked = true;
        for (var i = 0; i < ctrl.transactionRows.length; i++) {
            var index = ctrl.rowsToBeMerged.indexOf(ctrl.transactionRows[i].Id);
            if (index < 0) {
                checked = false;
            }
        }
        return checked;
    }

    /*
        Toggle all rows for merging
     */

    /*
        Toggle all rows for merging checkbox action
     */
    function mergeAll($event) {
        canMergeRows();
        toggleMergeAllRows();
        if (ctrl.allChecked()) {
            $document.find(".checkbox input").not($event.currentTarget).not("[disabled]").prop("checked", true);
        } else {
            $document.find(".checkbox input").not($event.currentTarget).not("[disabled]").prop("checked", false);
        }
    }

    function toggleMergeAllRows() {
        if (ctrl.transactionRows) {
            if (ctrl.allChecked()) {
                var i;
                for (i = 0; i < ctrl.transactionRows.length; i++) {
                    var index = ctrl.rowsToBeMerged.indexOf(ctrl.transactionRows[i].Id);
                    if (index >= 0) {
                        ctrl.rowsToBeMerged.splice(index, 1);
                    }
                }
            } else {
                for (i = 0; i < ctrl.transactionRows.length; i++) {
                    if (!ctrl.rowsToBeMerged.includes(ctrl.transactionRows[i].Id)) {
                        ctrl.rowsToBeMerged.push(ctrl.transactionRows[i].Id);
                    }
                }
            }
        } else {
            ctrl.rowsToBeMerged = [];
        }
    }

    function reloadTransactionRows(page) {
        ctrl.rowsToBeMerged = [];
        if (page === ctrl.paginator.currentPage) {
            return $timeout(function () {
                getTransactionRows($routeParams.id, canMergeRows);
            }, 25);
        }
        ctrl.paginator.currentPage = page;
        return page;
    }

    /*
        Merge all selected rows
        TODO use invoice.service.js instead
     */
    function mergeAccountingRows() {
        if (ctrl.rowsToBeMerged.length) {
            if (ctrl.invoice && ctrl.transactionRows.length > 1) {
                ctrl.MergeOpen = false;
                webServices.mergeAccountingRows(ctrl.rowsToBeMerged).then(function () {
                    reloadTransactionRows(ctrl.pageToReturnAfterMerge === -1 ? ctrl.paginator.currentPage : ctrl.pageToReturnAfterMerge);
                    ctrl.pageToReturnAfterMerge = -1; // reset value after return to correct page
                    $scope.$parent.reloadInvoiceData($routeParams.id);
                    ctrl.rowsToBeMerged = [];
                    ctrl.allCheckedCheckBox = ctrl.allChecked();
                    ctrl.isMergeDisabled = true;
                    ctrl.bypassPageChange = true;
                }, function (data) {
                    console.log(data);
                });
            }
            canMergeRows();
        } else {
            notifyService.info(
                "component.transactionRows.combineRowsMessage",
                "controller.invoiceConfirmationController.Error",
                true
            );
        }
    }

    function isAutoTransactionFinished(id, callback) {
        webServices.isAutoTransactionFinished(id).then(
            function (response) {
                if (response.data) {
                    if (ctrl.transactionRowsTimeout) {
                        $timeout.cancel(ctrl.transactionRowsTimeout);
                    }
                    updateInvoice($routeParams.id).then(
                        function () {
                            return reloadTransactionRows(1);
                        }
                    );
                } else {
                    ctrl.transactionRowsTimeout = $timeout(function () {
                        isAutoTransactionFinished(id, callback);
                    }, 1000);
                    return false;
                }
            }
        );
    }

    function getTransactionRows(id, callback, skipReload) {
        ctrl.metainfoLoading = true;
        ctrl.loading = true;
        if (!id) {
            return;
        }
        webServices.isAutoTransactionFinished(id).then(
            (r) => {
                if ((r && !r.data) && ctrl.activeInvoiceLoading) {
                    ctrl.transactionRowsTimeout = isAutoTransactionFinished(id, getTransactionRows(id, callback));
                    return false;
                }
                if (ctrl.transactionRowsLoadingInFlight) {
                    return;
                }
                ctrl.transactionRowsRendered = 0;
                ctrl.transactionRowsLoadingInFlight = true;
                const accountingDate = ctrl.invoice.AccountingDate ? ctrl.invoice.AccountingDate : undefined;
                $q.all([
                    webServices.getTransactionRowsExtendedInfoList(id),
                    companyDataService.getCustomCostObjectives(true, true, undefined, accountingDate),
                    webServices.getAccountingRowsWithPaging(id, ctrl.paginator.currentPage - 1, ctrl.paginator.count),
                    webServices.getInvoiceTransactionRowsVatRates(id),
                    webServices.getInvoiceTransactionRowsVatSum(id)
                ]).then(
                    function (response) {
                        ctrl.transactionRowsLoadingInFlight = false;
                        const metaInfos = response[0].data;
                        const customCostObjectives = response[1];
                        const transactionRows = response[2].data;
                        ctrl.allTransactionRowsVatRates = response[3].data || [];
                        ctrl.transactionRowsVatSum = response[4].data;
                        ctrl.customCostObjectives = customCostObjectives;

                        if ((transactionRows.length < 1) ||
                            (
                                !isEmpty(transactionRows) &&
                                !!transactionRows.find((item) => item.InvoiceId !== parseInt($routeParams.id))
                            )
                        ) {
                            ctrl.metainfos = [];
                            ctrl.transactionRows = [];
                            ctrl.TransactionRowsTotalSumRemaining = 0;
                            ctrl.VatSumDeviation = 0;
                            ctrl.metainfoLoading = false;
                            ctrl.loading = false;
                            $rootScope.$broadcast('transactionRowsLoaded', true);
                            return;
                        }
                        ctrl.metainfos = metaInfos;
                        ctrl.metainfoLoading = false;
                        var mandatoryFields = _.filter(customCostObjectives, function (t) {
                            return t.IsMandatory || t.IsVisible;
                        });
                        setTransactionRows(transactionRows, customCostObjectives);
                        ctrl.allCheckedCheckBox = ctrl.allChecked();
                        ctrl.loading = false;
                        if (callback && typeof callback === "function") {
                            callback();
                        }
                        if (ctrl.expandAllRowsDefault) {
                            ctrl.expandedRows = ctrl.transactionRows;
                        }
                    }, function () {
                        ctrl.loading = false;
                    }
                );
            }
        )
    }

    function setTransactionRows(transactionRows, customCostObjectives) {
        if (!ctrl.missingCustomCostObjectives.length) {
            midLayerMandatoryCOs.map(cco => {
                if (!customCostObjectives.some(p => p.Code === cco)) {
                    ctrl.missingCustomCostObjectives.push(cco);
                }
            });
            ctrl.missingCustomCostObjectivesText =
                ["<span class='child-row__missing-fileds-title'>" + $filter('translate')('component.transactionRows.missingBudgetFields') + "</span><br />",
                ...ctrl.missingCustomCostObjectives].join("<br />");
        }

        ctrl.transactionRows = transactionRows.map(function (transactionRow) {
            const row = ctrl.expandedRows.length && ctrl.expandedRows.find(r => r.Id === transactionRow.Id)
            if (row) {
                transactionRow.expanded = row.expanded;
            } else {
                transactionRow.expanded = ctrl.expandAllRowsDefault;
            }
            if (transactionRow.AccountingDate) {
                transactionRow.AccountingDate = new Date(utilityService.removeTimeZone(transactionRow.AccountingDate));
            }
            if (customCostObjectives.length > 0) {
                
                customCostObjectives.forEach(function (mf) {
                    const transactionRowHasCustomCostObjective = _.some(transactionRow.TransactionRowsDimensions, function (customField) {
                        const match = customField.CustomCostObjective.Id === mf.Id;
                        if (match) {
                            customField.CustomCostObjective.DimensionsCount = mf.DimensionsCount;
                        }
                        return match;
                    });
                    if (!transactionRowHasCustomCostObjective) {
                        transactionRow.TransactionRowsDimensions.push({
                            isMandatory: mf.IsMandatory,
                            CustomCostObjective: mf,
                            CustomCostObjectiveId: mf.Id,
                            IsVisible: mf.IsVisible
                        });
                    }
                });
            }
            addCustomDimensions(transactionRow.Account, transactionRow, customCostObjectives);
            setMandatoryFields(transactionRow, customCostObjectives);
            sortCustomFields(transactionRow);
            return transactionRow;
        });

        setVatSumDeviation();
    }

    function reloadTransactionRowsToUpdateRelatedDimensions(row) {
        companyDataService.getCustomCostObjectives(true).then(
            function (customCostObjectives) {
                addCustomDimensions(row.Account, row, customCostObjectives);
                row = sortCustomFields(row);
                webServices.getAccountingRowsWithPaging($routeParams.id, ctrl.paginator.currentPage - 1, ctrl.paginator.count).then(
                    (transactionRowsData) => {
                        const transactionRows = transactionRowsData.data;
                        setTransactionRows(transactionRows, customCostObjectives)
                    }
                )
            }
        );
    }

    function sortCustomFields(item) {
        item.TransactionRowsDimensions = item.TransactionRowsDimensions.sort(function (a, b) {
            if (a.CustomCostObjectiveId > b.CustomCostObjectiveId) {
                return 1;
            } else if (a.CustomCostObjectiveId < b.CustomCostObjectiveId) {
                return -1;
            } else {
                return 0;
            }
        });
        return item;
    }

    /*
        Merge all accounting rows
     */
    function mergeAllAccountingRows() {
        ctrl.rowsToBeMerged = [];
        ctrl.allCheckedCheckBox = ctrl.allChecked();
        if (ctrl.invoice && (ctrl.transactionRows.length > 1 || ctrl.invoice.InvoiceAccountingRowsTotal > 1)) {
            ctrl.loading = true;
            ctrl.MergeOpen = false;
            webServices.MergeAllAccountingRows(ctrl.invoice.Id).then(function () {
                reloadTransactionRows(1);
                updateInvoice($routeParams.id).then(
                    function (response) {
                        ctrl.rowsToBeMerged = [];
                        ctrl.allCheckedCheckBox = ctrl.allChecked();
                        ctrl.isMergeDisabled = true;
                        ctrl.bypassPageChange = true;
                        ctrl.loading = false;
                    }
                );
            }, function (data) {
                console.log(data);
            });
        }
        canMergeRows();
    }

    /*
     Single row toggle for merge
     */
    function toggleMergeSingleRow(id, $event) {
        // $event.stopPropagation();
        toggleMerge(id);
        if (ctrl.allChecked()) {
            $document.find("#accounting_rows_checkbox_all").prop("checked", true);
        } else {
            $document.find("#accounting_rows_checkbox_all").prop("checked", false);
        }
        canMergeRows();
    }

    /*
        Toggle row history
     */
    function toggleHistory(row) {
        row.historyVisible = !row.historyVisible;
        if (row.historyVisible && isEmpty(row.history)) {
            webServices.GetRowHistory(row.Id).then(function (response) {
                row.history = response.data;
            });
        }
    }

    /*
        Toggle a single parent row
     */
    function expandParentRow($event, parentRow) {
        $event.preventDefault();
        if (angular.element($event.target).closest(".table-transactions__checkbox").length ||
            angular.element($event.target).closest(".checkbox__label").length) {
            return;
        }
        if (parentRow.expanded) {
            parentRow.expanded = false;
            ctrl.expandedRows = ctrl.expandedRows.filter(r => r.Id !== parentRow.Id);
        } else {
            parentRow.expanded = true;
            ctrl.expandedRows.push(parentRow);
        }
    }

    /*
        Toggle a single child row
     */
    function expandChildRow($event, row) {
        if (angular.element($event.target).closest("input").length) {
            $event.stopPropagation();
            return;
        }
        row.expanded = !row.expanded;
    }

    /*
        Toggle all parent rows
     */
    function expandAllRows(e) {
        e.preventDefault();
        var i;
        if (ctrl.expandedRows.length === ctrl.transactionRows.length) {
            for (i = 0; i < ctrl.transactionRows.length; i++) {
                ctrl.transactionRows[i].expanded = false;
            }
            ctrl.expandedRows = [];
            ctrl.expandAllRowsDefault = false;
        } else {
            for (i = 0; i < ctrl.transactionRows.length; i++) {
                ctrl.transactionRows[i].expanded = true;
            }
            ctrl.expandedRows = ctrl.transactionRows;
            ctrl.expandAllRowsDefault = true;
        }
    }

    /** ****************** MAIN FUNCTIONS FOR TRANSACTION ROWS ********************/

    function applyAutotransactionEnabled() {
        /**
         * TODO: Rewrite $parent scope functions into this controller
         */
        return !!($scope.$parent.selectedTemplate || $scope.$parent.$parent.selectedTemplate);
    }

    function showCombinePopover() {
        if (!ctrl.invoice) return false;
        return (ctrl.invoice.hasOwnProperty("CanEditInvoice") && ctrl.invoice.CanEditInvoice) && ctrl.canUpdateAccountDistributionItem();
    }

    /*
        Function to check if rows can be merged
     */
    function canMergeRows() {
        const canMergeInvoiceStatuses = [
            InvoiceStatus.New,
            InvoiceStatus.InProgress,
            InvoiceStatus.InApproval
        ];

        if (!ctrl.invoice
            || !canMergeInvoiceStatuses.includes(ctrl.invoice.Status)
            || isEmpty(ctrl.transactionRows)
            || isEmpty(ctrl.allTransactionRowsVatRates)
        ) {
            ctrl.isMergeDisabled = true;
            return false;
        }

        const groupedVatRates = groupBy(ctrl.allTransactionRowsVatRates, 'm_Item2');

        Object.keys(groupedVatRates).forEach((key) => {
            if (size(groupedVatRates[key]) > 1) {
                ctrl.isMergeDisabled = false;
            }
        });
    }

    function mergeRows() {
        if (ctrl.rowsToBeMerged.length) {
            if (!ctrl.isMergeDisabled) {
                ctrl.loading = true;
                mergeAccountingRows();
                $document.find("#accounting_rows_checkbox_all").prop("checked", false);
                $document.find(".accounting_rows_checkbox").prop("checked", false);
            }
        } else {
            notifyService.info(
                "component.transactionRows.combineRowsMessage",
                "controller.invoiceConfirmationController.Error",
                true
            );
        }
    }

    function mergeAllRows() {
        if (ctrl.invoice.InvoiceAccountingRowsTotal > 1) {
            ctrl.loading = true;
            ctrl.combineTemplatePopover.Open = false;
            mergeAllAccountingRows();
            $document.find("#accounting_rows_checkbox_all").prop("checked", false);
            $document.find(".accounting_rows_checkbox").prop("checked", false);
        }
    }

    function focusInput($event) {
        angular.element($event.currentTarget).parent().find("input").focus();
    }

    function UpdateComment(row) {
        row.EditingComment = false;
        if (!row.Comment) {
            row.Comment = "";
        }
        row.historyVisible = false;
        row.history = {};
        webServices.updateComment(row.Id, row.Comment).then(
            function (response) {
            }
        );
    }

    function startUpdateComment(row) {
        row.EditingComment = true;
    }

    function updateSum(row) {
        row.SumLoading = true;
        webServices.updateSum(row.Id, row.SumWithVat);
    }

    function recalculateVatBySum(row) {
        if (row.SumWithoutVat == null || row.SumWithoutVat === "") {
            row.VAT = 0;
            row.Total = 0;
            return;
        }
        var newSumValue = Number(row.SumWithoutVat);
        var vatRate = row.VatRate;
        if (vatRate == null || vatRate === "") {
            vatRate = 0;
        }
        var newVat = (newSumValue * vatRate) / 100;
        row.VAT = vatRate === 0 ? 0 : Number(Math.round(newVat + "e2") + "e-2");
        row.Total = Number(Math.round(newSumValue + row.VAT + "e2") + "e-2").toFixed(2);
    }

    function recalculateVatByTotal(row) {
        if (row.Total == null || row.Total === "") {
            row.VAT = 0;
            row.SumWithoutVat = 0;
            return;
        }
        var newTotalValue = Number(row.Total);
        var vatRate = row.VatRate;
        if (vatRate == null || vatRate === "") {
            vatRate = 0;
        }
        var newVat = newTotalValue - (newTotalValue / (1 + (vatRate / 100)));
        row.VAT = vatRate === 0 ? 0 : Number(Math.round(newVat + "e2") + "e-2");
        var sumWithoutVat = newTotalValue - Number(row.VAT);
        row.SumWithoutVat = Number(Math.round(sumWithoutVat + "e2") + "e-2");
    }

    function recalculateVatByVatChange(row) {
        if (row.VAT == null || row.VAT === "") {
            return;
        }

        // Disabled recalculation as per comment https://fitekgroup.atlassian.net/browse/EMR-4875
        // leaving for future
        //
        // if (row.VATRate == null || row.VATRate === "") {
        //     row.VATRate = 0;
        // }

        // var newVat = Number(row.VAT);
        // var vatPercentage = Number(row.VatRate);

        // if (row.VatCode !== null && row.VatCode.VatRate !== null) {
        //     vatPercentage = row.VatCode.VatRate;
        // }

        // var newSumWithoutVat = newVat * 100 / vatPercentage;
        // var newTotal = newSumWithoutVat + newVat;

        // row.SumWithoutVat = Number(Math.round(newSumWithoutVat + "e2") + "e-2");
        // row.Total = Number(Math.round(newTotal + "e2") + "e-2");
    }

    function UpdateDate(row) {
        webServices.updateDate(row.Id, row.AccountingDate ? formatDate(row.AccountingDate, 'yyyy-MM-dd') : null);
        $analytics.eventTrack('Transaction rows', {
            category: 'Invoice Details',
            label: 'Edit accounting date',
        });
    }

    function updateRow(row, clearConfirmer) {
        if (validateTransactionRow(row)) {
            /**
             *  TODO @VladimirKruglov:
             *  The function confirmAccountDistributionItem was deleted in a2632632401c1093d265bcb3c86699ea714a181b,
             *  but the reference was not fixed.
             *  Please check this function and help correct this.
             */
            ctrl.confirmAccountDistributionItem(row, clearConfirmer, false);
        } else {
            row.AccountLoading = false;
        }
    }

    function addComment(item) {
        item.EditingComment = true;
    }

    function removeComment(row) {
        row.Comment = "";
        row.EditingComment = false;
        row.historyVisible = false;
        row.history = {};
        webServices.updateComment(row.Id, "");
    }

    function disableTemplateSelection() {
        ctrl.confirmableRows = [];
        ctrl.rowsCountString = "";
        if (ctrl.invoice && ctrl.transactionRows) {
            var i;
            for (i = 0; i < ctrl.transactionRows.length; i++) {
                if (ctrl.isUserNextConfirmerInRow(ctrl.transactionRows[i])) {
                    ctrl.confirmableRows.push(i + 1);
                }
            }
            for (i = 0; i < ctrl.confirmableRows.length; i++) {
                if (i === ctrl.confirmableRows.length - 1 && ctrl.confirmableRows.length >= 2) {
                    ctrl.rowsCountString += " & ";
                } else if (i !== 0 && ctrl.confirmableRows.length >= 2) {
                    ctrl.rowsCountString += ", ";
                }
                ctrl.rowsCountString += ctrl.confirmableRows[i];
            }
        }
        if (ctrl.invoice && ctrl.transactionRows && ctrl.transactionRows.length === ctrl.confirmableRows.length) {
            return false;
        } else {
            return !!ctrl.confirmableRows.length;
        }
    }

    /** ****************** FUNCTIONS TO CHECK FOR AUTHORIZATION ********************/

    function canSaveInvoice() {
        return (authenticationService.isAuthorized("CanAddInvoice") && ctrl.invoice && !ctrl.invoice.Id) ||
            (authenticationService.isAuthorized("CanUpdateInvoice") && ctrl.invoice && ctrl.invoice.Id);
    }

    function canAddTransactionRows() {
        return (authenticationService.isAuthorized("CanAddTransactionRows"));
    }

    function getBudget(row) {
        
        row.budgetWarning = false;

        if (!!ctrl.missingCustomCostObjectives.length) {
            return;
        }

        ctrl.validateTransctRows = true;
        if (validateTransactionRow(row)) {
            const params = [];
            row.TransactionRowsDimensions.map(row => {
                if (midLayerMandatoryCOs.some(p => p === row.CustomCostObjective.Code)) {
                    if (!!row.Dimension) {
                        params.push({ name: row.CustomCostObjective.Code, value: row.Dimension.Code });
                    } else {
                        return;
                    }
                } else if (midLayerOptionalCOs.some(p => p === row.CustomCostObjective.Code && !!row.Dimension)) {
                    params.push({ name: row.CustomCostObjective.Code, value: row.Dimension.Code });
                }
            });

            const model = params.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {})
            row.budget = "..."
            webServices.getAvailableIsibFunds(model).then(function (resp) {
                if(!resp.data){
                    row.budget = "error";
                    return;
                }
                if(resp.data.Message){
                    row.budgetWarning = true;
                    row.budget = resp.data.Message;
                    return;
                }
                if (resp.data.Result) {
                    row.budget = resp.data.Result.AvailableFunds;
                    if(parseFloat(row.budget) < row.ItemAmount){
                        row.budgetWarning = true;
                    }
                    return;
                }
                row.budget = "error";
            });
        }
    }

    function checkTextAreaHeight() {
        try {
            autoGrow();
        } catch (err) {
        }
        return true;
    }

    /** ****************** HELPER FUNCTIONS AND DATA PARSERS ********************/

    function setMessagePriority() {
        ctrl.rowsCombinedMessage = false;
        ctrl.rowsEditedMessage = false;
        ctrl.differentVatMessage = false;
    }

    function validateRows(row) {
        if (row.Description) {
          row.showValidation = false;
          return true;
        } else {
          row.showValidation = true;
          return false;
        }
      }

    /** UNUSED WRAPPER FUNCTION
     *
     * function validateTransactionRow(transaction) {
      return validateTransactionRow(transaction);
    }**/

    function validateTransactionRow(row) {
        if (!ctrl.isTransactionRowsCheckEnabled && !ctrl.isTransactionRowsPenultimateCheckEnabled) {
            // skip all verifications
            return true;
        }
        var test = true;
        row.showValidation = false;

        const skipAccountAndVatErrors = ctrl.isTransactionRowsPenultimateCheckEnabled && invoiceService.isPenultimateConfirmer(ctrl.invoice);

        if (ctrl.validateTransctRows && ctrl.getAccountVatCodes("", 0, row.VatRate).length > 0) {
            if (row.TransactionRowsDimensions && !row.AccountDescription && !skipAccountAndVatErrors) {
                test = false;
                row.showAccountValidation = true;
            }
            if (row.TransactionRowsDimensions && !row.VatCode && !skipAccountAndVatErrors) {
                test = false;
                row.showVatValidation = true;
            }
            if (!row.TransactionRowsDimensions && ctrl.TransactionRowsTotalSumRemaining !== 0) {
                test = false;
                row.showValidation = true;
            }
            if (ctrl.invoice.InvalidRowsIds && ctrl.invoice.InvalidRowsIds.length > 0) {
                if (ctrl.invoice.InvalidRowsIds.indexOf(row.Id) >= 0) {
                    test = false;
                    row.showValidation = true;
                }
            }
            row.TransactionRowsDimensions.forEach(function (customField) {
                const typeForm = customField.childTypeTransactionRowsForm;
                const form = customField.childTransactionRowsForm;
                const mandatoryAdditionalField = row.Account && row.Account.AdditionalFields.find((af) => af.CustomCostObjectiveId === customField.CustomCostObjectiveId);
                if (((typeForm && typeForm.$invalid) || (form && form.$invalid)) && !ctrl.validateTransctRows) {
                    test = false;
                    row.showValidation = true;
                } else if (ctrl.validateTransctRows && (customField.isMandatory || !!mandatoryAdditionalField) && !customField.DimensionId) {
                    test = false;
                    if (form && form.customFieldValue) {
                        form.customFieldValue.$setValidity('reg', false);
                    }
                    row.showValidation = true;
                }
            });
        }
        return test;
    }

    /*
    * Paginator functions
    */
    /* End of Paginator functions */

    /*
    * XLS file import
    */
    function openModalXlsImport() {
        $rootScope.modalInstance.open({
            templateUrl: "app/components/transactionRows/ds-import-xls-modal.html",
            controller: "DsImportXlsController",
            windowClass: "ds-import-xls-modal",
            scope: $scope
        });
    }

    /** ROOTSCOPE EVENT HANDLERS **/

    var activeInvoiceLoadingEvent = $rootScope.$on('activeInvoiceLoading', function (evt) {
        ctrl.activeInvoiceLoading = true;
        ctrl.loading = true;
    });

    var activeInvoiceLoadedEvent = $rootScope.$on('activeInvoiceLoaded', function (evt) {
        ctrl.activeInvoiceLoading = false;
    });

    var transactionRowsLoadingEvent = $rootScope.$on("transactionRowsLoading", function (evt) {
        ctrl.loading = true;
    });
    var transactionRowsLoadEvent = $rootScope.$on("loadTransactionRows", function (evt, page) {
        reloadTransactionRows(page || 1);
    });

    var transactionRowCustomFieldsRenderFinishedEvent = $rootScope.$on('formInitialized', (e, data) => {
        switch (data) {
            case ctrl.formInitializers.transactionRows:
                ctrl.transactionRowsRendered += 1;
                break;
            case ctrl.formInitializers.vatCode:
                ctrl.vatCodesRendered += 1;
                break;
            case ctrl.formInitializers.customFields:
                ctrl.customFieldsRendered += 1;
                break;
            default:
                break;
        }
        if (ctrl.transactionRows.length > 0 && ctrl.transactionRowsRendered === ctrl.transactionRows.length) {
            if (ctrl.transactionRows[0].TransactionRowsDimensions.length > 0 && ctrl.customFieldsRendered === ctrl.transactionRows.length) {
                $rootScope.$broadcast('transactionRowsLoaded', true);
                return;
            }
            if (ctrl.vatCodesRendered === ctrl.transactionRows.length) {
                $rootScope.$broadcast('transactionRowsLoaded', true);
            }
        }
    });

    var mustValidateTransactionRowsEvent = $rootScope.$on("mustValidateTransactionRows", function (evt, data) {
        ctrl.validateTransctRows = true;
        if (data && data.length > 0) {
            for (var i = 0; i < ctrl.transactionRows.length; i++) {
                var foundRow = (function (iterator) {
                    return _.find(data,
                        function (r) {
                            return r === ctrl.transactionRows[iterator].Id;
                        });
                })(i);
                if (foundRow) {
                    validateTransactionRow(ctrl.transactionRows[i]);
                } else {
                    ctrl.transactionRows[i].showValidation = false;
                }
            }
        }
    });

    var isTransactionRowsCheckEnabledEvent = $rootScope.$on("isTransactionRowsCheckEnabled", function (e, val) {
        ctrl.isTransactionRowsCheckEnabled = val;
    });

    var isTransactionRowsPenultimateCheckEnabledEvent = $rootScope.$on("isTransactionRowsPenultimateCheckEnabled", function (e, val) {
        ctrl.isTransactionRowsPenultimateCheckEnabled = val;
    });

    var resetTransactionRowsPaginatorEvent = $rootScope.$on("resetTransactionRowsPaginator", function (evt) {
        ctrl.paginator.currentPage = 1;
    });
    /** SCOPE WATCHERS **/

    var currentPageWatcher = $scope.$watch(function () {
        return ctrl.paginator.currentPage;
    }, function (newVal, oldVal) {
        if (oldVal.toString() !== newVal.toString()) {
            getTransactionRows($routeParams.id, canMergeRows);
        }
    });
    var invoiceIdWatcher = $scope.$watch(function () {
        return ctrl.invoiceId;
    }, function (newVal, oldVal) {
        if (newVal.toString() !== oldVal.toString()) {
            reloadTransactionRows(1);
        }
    });
    var invoiceWatcher = $scope.$watch(function () {
        return ctrl.invoice;
    }, function (newVal) {
        if (newVal) {
            ctrl.TransactionRowsTotalSumRemaining = ctrl.invoice.TotalAmountWithVat - ctrl.invoice.TransactionRowsTotalSumRemaining;
            ctrl.paginator.total = ctrl.invoice.InvoiceAccountingRowsTotal;
            /**
             * TODO: properly check for this param
             */
            ctrl.showAddRow = ctrl.invoice.CanEditInvoice &&
                ctrl.TransactionRowsTotalSumRemaining !== 0 &&
                ctrl.invoice.InvoiceAccountingRowsTotal > 0 &&
                !ctrl.loading;
            canMergeRows();
        }
    });
    var transactionRowsWatcher = $scope.$watch(function () {
        return ctrl.transactionRows.length;
    }, function (newVal, oldVal) {
        if (newVal !== oldVal) {
            canMergeRows();
        }
    });

    /*
        Clear all event listeners & watchers on destroy
     */
    function onDestroy() {
        // isMergeDisabledWatch();
        if (invoiceIdWatcher) invoiceIdWatcher();
        if (currentPageWatcher) currentPageWatcher();
        if (invoiceWatcher) invoiceWatcher();
        if (transactionRowsWatcher) transactionRowsWatcher();
        transactionRowsLoadEvent();
        transactionRowsLoadingEvent();
        mustValidateTransactionRowsEvent();
        isTransactionRowsCheckEnabledEvent();
        isTransactionRowsPenultimateCheckEnabledEvent();
        resetTransactionRowsPaginatorEvent();
        activeInvoiceLoadingEvent();
        activeInvoiceLoadedEvent();
        transactionRowCustomFieldsRenderFinishedEvent();
    }

    ctrl.$onChanges = function (changes) {
        // init requests when we have invoice AccountingDate
        if (changes.invoice && changes.invoice.currentValue && changes.invoice.currentValue.AccountingDate) {
            if (typeof changes.invoice.currentValue.AccountingDate.toISOString === 'function') {
                // it's Date type
                const accountingDate = changes.invoice.currentValue.AccountingDate.toISOString();
                init(accountingDate);
            } else {
                // it must be ISO string already
                init(changes.invoice.currentValue.AccountingDate);
            }
        }
    };

    function settingsInit(settings) {
        const isTransactionRowsValidationEnabled = settings.find((setting) => setting.Name === CompanySetting.IsTransactionRowsCheckEnabled);
        const isTransactionRowsPenultimateCheckEnabled = settings.find((setting) => setting.Name === CompanySetting.IsTransactionRowsPenultimateCheckEnabled);
        const isVatSumDeviationWarningEnabled = settings.find((setting) => setting.Name === CompanySetting.IsVatSumDeviationWarningEnabled);
        const hasMidLevelEndpoint = settings.find((setting) => setting.Name === CompanySetting.SerbiaMidLayerEndpoint);

        ctrl.isTransactionRowsCheckEnabled = isTransactionRowsValidationEnabled && isTransactionRowsValidationEnabled.Value === CompanySettingStatus.Enabled;
        ctrl.isTransactionRowsPenultimateCheckEnabled = isTransactionRowsPenultimateCheckEnabled && isTransactionRowsPenultimateCheckEnabled.Value === CompanySettingStatus.Enabled;
        ctrl.isVatSumDeviationWarningEnabled = isVatSumDeviationWarningEnabled && isVatSumDeviationWarningEnabled.Value === CompanySettingStatus.Enabled;
        ctrl.hasMidLevelEndpoint = hasMidLevelEndpoint && !!hasMidLevelEndpoint.Value;
    }

    function init(accountingDate) {
        ctrl.loading = true;
        ctrl.transactionRowsRendered = 0;
        ctrl.vatCodesRendered = 0;
        ctrl.customFieldsRendered = 0;
        ctrl.templateApplying = localStorageService.get("transactionRows.ApplyingTemplate") || {
            inProgress: false,
            transactionRowId: null
        };
        $q.all([
            companyDataService.getAccounts(true, accountingDate),
            companyDataService.getVatCodes(true, accountingDate),
            // companyDataService.getCustomCostObjectives(true)
        ]).then(
            function (response) {
                ctrl.AccountList = response[0];
                ctrl.VatCodes = response[1];
                // ctrl.customCostObjectives = utilityService.hardCopy(response[2]);
                ctrl.companyDataLoaded = true;

                const { company: { currentCompanySettings } } = $ngRedux.getState();
                let settings;
                if (currentCompanySettings && currentCompanySettings.length) {
                    settings = currentCompanySettings;
                    settingsInit(settings);
                } else {
                    // get Company Settings only if not in Redux state yet
                    $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
                    webServices.getCurrentCompanySettings().then(function (resp) {
                        $ngRedux.dispatch(setCurrentCompanySettings(response.data));
                        $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
                        settings = resp.data;
                        settingsInit(settings);
                    }, function (data) {
                        $ngRedux.dispatch(setCurrentCompanySettingsLoading(false));
                        console.log(data);
                    })
                }

                if (ctrl.templateApplying && !ctrl.templateApplying.inProgress) {
                    updateInvoice();
                }

                if (ctrl.invoice) {
                    const invoiceStatus = ctrl.invoice.Status;
                    const editableStatuses = [InvoiceStatus.New, InvoiceStatus.InApproval];
                    const isVatCodeManualEditingEnabled = settings.find((setting) => setting.Name === CompanySetting.IsVatCodeManualEditingEnabled);
                    ctrl.isVatCodeManualEditingEnabled = editableStatuses.indexOf(invoiceStatus) > -1 && isVatCodeManualEditingEnabled && isVatCodeManualEditingEnabled.Value === CompanySettingStatus.Enabled;
                }
            }
        );
    }

    function processExport(exportType) {
        const exportMethod = exportType === 0 ?  webServices.exportTransactionRowsWithFormulasToXls : exportType === 1 ? webServices.exportTransactionRowsToXls : webServices.exportTransactionRowsToCsv;

        exportMethod(ctrl.invoiceId).then(function (response) {
            if (response) {
                if (response.status === 200) {
                    if (exportType === 0) {
                        const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        saveAs(file, `Invoice_${ctrl.invoiceId}_transaction-rows-with-formulas.xlsx`);
                    } 
                    else if (exportType === 1) {
                        const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
                        saveAs(file, `Invoice_${ctrl.invoiceId}_transaction-rows.xlsx`);
                    } else {
                        const file = new Blob([response.data], { type: "text/csv;charset=utf-8" });
                        saveAs(file, `Invoice_${ctrl.invoiceId}_transaction-rows.csv`);
                    }

                } else if (response.status === 400 && response.data) {
                    if (exportType === 2) {
                        notifyService.error(response.data.ErrorCode ? $filter("translate")(response.data.ErrorCode).replace('{0}', response.data.FieldName || "") : response.data.Message);
                    } else {
                        response.data.text().then(function (response) {
                            const data = JSON.parse(response);
                            notifyService.error(data.ErrorCode ? $filter("translate")(data.ErrorCode).replace('{0}', data.FieldName || "") : data.Message);
                        });
                    }
                }
            }
        }).catch(function (e) {
            notifyService.error(e, true);
        });
    }
}