import React from 'react';
import { TFunction } from 'i18next';
import { isEqual } from 'lodash-es';

import { InvoiceDTO } from '../../../../../services/types/ApiTypes';
import { Button, ButtonType, ButtonIconPlacement } from '../../../../../components/Buttons/Button';
import { ICONS } from '../../../../../components/Icon/Icon';
import { createDataId } from '../../../../../common/utils/dataId';
import PropertyListItem from '../../../../../components/PropertyListItem/PropertyListItem';
import { isAuthorized, Role } from '../../../../../common/user/userPermissionUtil';

const localDataId = 'additional-info';

const AdditionalInfoViewMode = ({ currentCompanyName, dataId, invoice, onEdit, t }: { currentCompanyName: string; dataId: string; invoice: InvoiceDTO; onEdit: () => void | null; t: TFunction }) => (
    <>
        <div className="invoice-header__fields">
            <ul>
                <PropertyListItem dataId={createDataId(dataId, localDataId)} label={t('component.additionalInfo.buyer')} value={currentCompanyName} hideOverflow={true} />
                <PropertyListItem dataId={createDataId(dataId, localDataId)} label={t('component.additionalInfo.buyerContact')} value={invoice?.ContactName} hideOverflow={true} />
                <PropertyListItem dataId={createDataId(dataId, localDataId)} label={t('component.additionalInfo.purchaseOrder')} value={invoice?.PurchaseOrder} hideOverflow={true} />
            </ul>
            <ul>
                <PropertyListItem dataId={createDataId(dataId, localDataId)} label={t('component.additionalInfo.supplierAddress')} value={invoice?.Supplier?.Address} hideOverflow={true} />
                <PropertyListItem
                    dataId={createDataId(dataId, localDataId)}
                    label={t('component.additionalInfo.supplierContactName')}
                    value={invoice?.Supplier?.ContactPersonName}
                    hideOverflow={true}
                />
                <PropertyListItem dataId={createDataId(dataId, localDataId)} label={t('component.additionalInfo.supplierContactEmail')} value={invoice?.Supplier?.Email} hideOverflow={true} />
                <PropertyListItem dataId={createDataId(dataId, localDataId)} label={t('report.InvoiceFrontPage.InvoiceType')} value={invoice?.InvoiceType?.Code} hideOverflow={true} />
                <PropertyListItem dataId={createDataId(dataId, localDataId)} label={t('component.additionalInfo.paymentMethod')} value={invoice?.PaymentMethod?.Code} hideOverflow={true} />
            </ul>
        </div>
        {!isAuthorized(Role.CanOnlyViewInvoices) && !!onEdit && (
            <div className="text-button-wrapper edit-button">
                <Button buttonType={ButtonType.ICON_TEXT} dataId="invoice-header.editAdditionalInfo" icon={ICONS.EDIT} iconPlacement={ButtonIconPlacement.LEFT} onClick={onEdit}>
                    {t('component.invoiceHeader.editAdditionalInfo')}
                </Button>
            </div>
        )}
    </>
);

export default React.memo(AdditionalInfoViewMode, isEqual);
