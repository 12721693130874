import * as React from 'react';
import { ICONS } from '../../Icon/Icon';
import { BaseComponent } from '../../BaseComponent';
import { MainMenuItem } from './MainMenuItem';
import MainMenuBody from './MainMenuBody';
import { MainMenuProps } from '../MainMenu';
import { paths } from '../../../common/router/routePaths';

export class MainMenuBO extends BaseComponent<MainMenuProps> {
    render() {
        return (
            <MainMenuBody {...this.props}>
                <MainMenuItem href={`#${paths.backOffice.boUsers}`} icon={ICONS.MENU_BO_USER} dataId="side-menu-link-bo-BOuser">
                    {this.props.t('view.backOffice.users')}
                </MainMenuItem>
                <MainMenuItem href={`#${paths.backOffice.domains}`} icon={ICONS.MENU_BO_DOMAINS} dataId="side-menu-link-bo-BOdomains">
                    {this.props.t('view.backOffice.domains')}
                </MainMenuItem>
                <MainMenuItem href={`#${paths.backOffice.integrators}`} icon={ICONS.MENU_BO_INTEGRATORS} dataId="side-menu-link-bo-integrators">
                    {this.props.t('view.backOffice.integrators')}
                </MainMenuItem>
                <MainMenuItem href={`#${paths.backOffice.resellers}`} icon={ICONS.MENU_BO_RESELLER} dataId="side-menu-link-bo-re-sellers">
                    {this.props.t('view.backOffice.resellers')}
                </MainMenuItem>
                <MainMenuItem href={`#${paths.backOffice.organizations}`} icon={ICONS.MENU_BO_ORGANIZATIONS} dataId="side-menu-link-organizations">
                    {this.props.t('view.backOffice.organizations')}
                </MainMenuItem>
                <MainMenuItem href={`#${paths.backOffice.companies}`} icon={ICONS.MENU_BO_COMPANIES} dataId="side-menu-link-companies">
                    {this.props.t('view.backOffice.companies')}
                </MainMenuItem>
                <MainMenuItem href={`#${paths.backOffice.users}`} icon={ICONS.MENU_BO_USER} dataId="side-menu-link-bo-user">
                    {this.props.t('view.backOffice.allUsers')}
                </MainMenuItem>
                <MainMenuItem href={`#${paths.backOffice.reports}`} icon={ICONS.TEMPLATE_24} dataId="side-menu-link-bo-reports">
                    {this.props.t('view.backOffice.reporting')}
                </MainMenuItem>
            </MainMenuBody>
        );
    }
}
